import React, { useState } from "react";
import { Modal, Alert, Button, FormField, Input, Toggle, Multiselect, ProgressBar, Textarea, SpaceBetween, Box, Form, ProgressBarProps } from "@amzn/awsui-components-react/polaris";
import { ApprovalSyncRequest } from '../../../fondue-api/generated-src';
import FondueApiFactory from '../../../fondue-api/FondueApiFactory';

import {REPORT_ID_REGEX} from '../../../common/constants'

export interface ApprovalSyncRequestFormProps {
    reportId: string,
    executionId: string,
    onDissmiss?: () => void
}

const ErrorTexts = {
    EmptyReportId: "Empty Fondue report id",
    InvalidReportId: "Invalid report id format"
} 

export default function ApprovalSyncRequestForm({reportId, executionId, ...props}: ApprovalSyncRequestFormProps): JSX.Element {
    const [visible, setVisible] = useState(true);
    const [classicReportId, setClassicReportId] = useState<string>("");
    const [reportIdError, setReportIdError] = useState<string>("");
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const [syncStatus, setSyncStatus] = useState<ProgressBarProps.Status>("in-progress");
    const [syncStatusText, setSyncStatusText] = useState<string>("Not submitted");


    function dismissModal() {
        setVisible(false);
        if (props.onDissmiss != undefined) {
            props.onDissmiss();
        }
    }

    async function submit() {
        if (classicReportId == "") {
            setReportIdError(ErrorTexts.EmptyReportId);
            return;
        } else {
            setReportIdError("");
        }

        // TODO: API call to request sync
        let requestData: ApprovalSyncRequest = {
            report_id: reportId,
            execution_id: executionId,
            classic_report_id: classicReportId
        }


        // Valding report_id based on regex
        var validReportId = REPORT_ID_REGEX.test(classicReportId)

        if(!validReportId){
            setReportIdError(ErrorTexts.InvalidReportId);
            return;
        } else{
            setReportIdError("")
        }

        setSubmitDisabled(true);

        await FondueApiFactory().requestApprovalSync(requestData)
            .then((_) => {
                setSyncStatus("success");
                setSyncStatusText("Approval sync requested");
            })
            .catch((error) => {
                setSyncStatus("error");
                setSyncStatusText("Error requesting Approval sync");
                setSubmitDisabled(false);
            })
    }

    return (
        <Modal
            id="approval-sync-modal"
            visible={visible}
            onDismiss={dismissModal}
            header="Approval Sync Request"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button id="cancel-button" variant="link" onClick={dismissModal}>Cancel</Button>
                        <Button id="submit-button" variant="primary" disabled={submitDisabled} onClick={submit}>Submit</Button>
                    </SpaceBetween>
                </Box>
            }>
                <Form>
                    <FormField
                        id="classic-report-id-field"
                        label="Fondue Report id"
                        description={
                            <React.Fragment>
                                Classic partition Fondue report id used to request approval sync. <a target="_blank" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_resource.html">Learn more</a> {/*TODO: Update to wiki link*/}
                            </React.Fragment>
                        }
                        constraintText="ID must be prefixed with report_. Valid characters are lowercase a-z, 0-9 and - (hyphen)"
                        errorText={reportIdError}>
                        <Input
                            id="classic-report-id-input"
                            value={classicReportId}
                            placeholder={"report_a1234567-a123-a123-a123-a1234567"}
                            onChange={event => setClassicReportId(event.detail.value)}
                        />
                    </FormField>
                </Form>
                <ProgressBar
                    id="approval-sync-status"
                    status={syncStatus}
                    label="Sync Status"
                    resultText={syncStatusText}
                    />
        </Modal>
    );
}