import { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import { States, FlashbarType } from "../../common/States";
import { useParams } from "react-router-dom";
import { Box, ContentLayout, Header} from '@amzn/awsui-components-react/polaris';
import React from 'react';
import RunReportViewComponent from './RunReportViewComponent';
import * as constants from '../../common/constants';
export default function RunReportView() {
    const [state, setState] = useState<States>(States.loading)
    const [reportName, setReportName] = useState('')
    const { report_id } = useParams<{report_id: string}>();
    return (
        <AppLayout
            headerSelector='#topNavigation'
            navigation={<ServiceNavigation />}
            breadcrumbs={<Breadcrumbs items={[
                { text: constants.FONDUE_LABEL, href:'' },
                { text: constants.REPORTS_LABEL, href: '#/reports' },
                { text: reportName, href: '#/reports/'+ report_id + '/result' },
                { text: 'Run Report', href: '' }]} />}
            content={
                <ContentLayout
                    header={
                        <Header variant="h2">
                            {reportName}
                        </Header>
                    }
                >
                    <Box>
                        <RunReportViewComponent report_id={report_id} reportName={reportName} setReportName={setReportName} state={state} setState={setState}/>
                    </Box>
                </ContentLayout>
                
            }
            contentType="table"
            toolsHide={true}
            ariaLabels={appLayoutLabels}
        />
    );
}