import { Component, useEffect, useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Flashbar, { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import './styles.scss';
import { Box, Grid, ColumnLayout, Header, Table, SpaceBetween, Alert, Container, ContentLayout } from '@amzn/awsui-components-react/polaris';
import Button from "@amzn/awsui-components-react/polaris/button";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import ReportContainer from "./ReportContainer";
import ReportItemsTable from './ReportItemsTable';
import ReportTableProps from './ReportItemsTable'
import ReportItemForm from './ReportItemForm';
import { Report, ReportItem, FondueApi } from "../../fondue-api/generated-src";
import { States, FlashbarType } from "../../common/States";
import { CustomStateParams, setFlashbarCustomError } from '../../common/CustomStateParams'
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import EditMetricForm from './EditMetricForm';
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import * as constants from '../../common/constants';
import { useDocumentTitle } from '../hooks';
import {SplitPanel} from '@amzn/awsui-components-react/polaris';


export default function ReportItemsTableView() {
    Object.freeze(Object.prototype);
    const { report_id } = useParams<{report_id: string}>();
    const [state, setState] = useState<States>(States.loading)
    const [customStateParams, setCustomStateParams] = useState<CustomStateParams>()
    const [reportName, setReportName] = useState("");
    const [refreshTable, setRefreshTable] = useState<boolean>(false);
    const [report, setReport] = useState<Report>();
    const [reportItem, setReportItem] = useState<ReportItem>();
    const [updatingReportItem, setUpdatingReportItem] = useState<'INIT'|'WAIT'|'DONE'>('INIT');
    const [editSelectReportItem, setEditSelectReportItem] = useState('')
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);
    const [splitPanelPosition, setSplitPanelPosition] = useState<'bottom' | 'side'>('side');
    const [splitPanelTitle, setSplitPanelTitle] = useState(constants.ADD_OR_EDIT_REPORT_ITEM_LABEL);
    useDocumentTitle(reportName ? `${reportName} ${constants.EDIT_REPORT_TAB_TITLE}`: constants.FONDUE_REPORTING_TITLE)
    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>([]);

    // Used to define allReportItems in Table and use them in different components, example EditMetricForm
    const [allReportItems, setAllReportItems] = useState<ReportItem[]>([]);

    const setFlashBarWithMessage = (type: FlashbarType, message: string): void => {
        setFlashbar([{
            type: type,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            content: (message),
            onDismiss: () => setFlashbar([])
        }])
    }

    const splitPanelI18nStrings = {
        preferencesTitle: 'Split panel preferences',
        preferencesPositionLabel: 'Split panel position',
        preferencesPositionDescription: 'Choose the default split panel position for the service.',
        preferencesPositionSide: 'Side',
        preferencesPositionBottom: 'Bottom',
        preferencesConfirm: 'Confirm',
        preferencesCancel: 'Cancel',
        closeButtonAriaLabel: 'Close panel',
        openButtonAriaLabel: 'Open panel',
        resizeHandleAriaLabel: 'Resize split panel',
    };

    async function getReport(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            await FondueApi.getReport(report_id)
                .then((response) =>{
                    setReport(response.data)
                })
                .catch((e) => {
                    getReport(FondueApi, counter + 1)
                    if(e.response.status=403){
                        console.log('User is not authorized to view this report.')
                        setState(States.getReportForbidden)
                    }
                })
        }
    }
    const [addEditInfoDisabled, setAddEditInfoDisabled] = useState(false);
    const [addDisabled, setAddDisabled] = useState(true);
    const [editDisabled, setEditDisabled] = useState(true);
    function handleSplitPanelPreferences(detail) {
        if (detail?.position){
            setSplitPanelPosition(detail?.position)
        }
    }

    useEffect(() => {
        if(state === States.loading){
            setFlashBarWithMessage(FlashbarType.info, constants.FLASHBAR_LOADING_REPORT_MESSAGE);
        }
        else if(state === States.submitting){
            setFlashBarWithMessage(FlashbarType.info, constants.FLASHBAR_CREATE_NEW_REPORT_ITEM_INFO);

        }
        else if(state === States.invalid){
            setFlashBarWithMessage(FlashbarType.error, constants.FLASHBAR_REPORT_ITEM_INVALID_INPUT_ERROR);

        }
        else if(state === States.deletingReport){
            setFlashBarWithMessage(FlashbarType.info, constants.FLASHBAR_DELETE_REPORT_INFO);

        }
        else if(state === States.failedCreate){
            if(customStateParams?.customErrorMessage) {
                setFlashBarWithMessage(FlashbarType.error, customStateParams?.customErrorMessage)
            }
            else {
                setFlashBarWithMessage(FlashbarType.error, constants.FLASHBAR_CREATE_NEW_REPORT_ITEM_ERROR);

            }
        }
        else if(state === States.savingReport){
            setFlashBarWithMessage(FlashbarType.info, constants.FLASHBAR_SAVING_REPORT_ORDER_INFO);

        }
        else if(state === States.saveReportSuccess) {
            setFlashBarWithMessage(FlashbarType.success, constants.FLASHBAR_REPORT_ORDER_UPDATE_SUCCESS);

        }
        else if(state === States.updatingReportItem){
            setFlashBarWithMessage(FlashbarType.info, constants.FLASHBAR_UPDATE_REPORT_ITEM_INFO);

        }
        else if(state === States.updateReportItemSuccess){
            setFlashBarWithMessage(FlashbarType.success, constants.FLASHBAR_UPDATE_REPORT_ITEM_SUCCESS);

        }
        else if(state === States.updateReportItemFailed){
            setFlashBarWithMessage(FlashbarType.error, constants.FLASHBAR_UPDATE_REPORT_ITEM_INFO);

        }
        else if(state === States.getReportForbidden){
            setFlashBarWithMessage(FlashbarType.error, constants.FLASHBAR_USER_AUTHORIZATION_VIEW_REPORT_ERROR);

        }
        else if(state === States.deleteReportForbidden){
            setFlashBarWithMessage(FlashbarType.error, constants.FLASHBAR_USER_AUTHORIZATION_DELETE_REPORT_ERROR);

        }
        else if(state == States.error){
            setFlashBarWithMessage(FlashbarType.error, constants.FLASHBAR_REPORT_FAILED_TO_LOAD_ERROR);

        }
        else if(state === States.updateReportItemConsoleDatashareDeny){
            setFlashBarWithMessage(FlashbarType.error, constants.FLASHBAR_UPDATE_REPORT_ITEM_CONSOLE_DATASHARE_ERROR)
        }
        else{
            setFlashbar([]);
        }

    }, [state, refreshTable]);
    useEffect(() => {
        if(!editDisabled) {
            setSplitPanelOpen(true);
        }
        else if(!addDisabled == false && !editDisabled == false ) {
            setSplitPanelOpen(false);
            setSplitPanelTitle(constants.ADD_OR_EDIT_REPORT_ITEM_LABEL)
        }
    }, [addDisabled, editDisabled])
    return (
        <AppLayout
            headerSelector='#topNavigation'
            navigation={<ServiceNavigation />}
            notifications={<Flashbar items={flashbar} />}
            breadcrumbs={<Breadcrumbs items={[
                { text: constants.FONDUE_LABEL, href:'' },
                { text: constants.REPORTS_LABEL, href: '#/reports' },
                { text: reportName, href: '#/reports/'+ report_id + '/result' },
                { text: 'Edit', href: '' }]} />}
            content={
                <ContentLayout 
                    header={
                        <Header
                        variant="h2"
                    >
                    </Header>
                    }
                >
                    <Box>
                            <div>
                                <Box padding={{bottom: "s"}}>
                                    <ReportContainer report={report as Report} setReport={setReport} setReportName={setReportName} setState={setState} setAddDisabled={setAddDisabled} setEditDisabled={setEditDisabled} setAddEditInfoDisabled={setAddEditInfoDisabled}/>
                                </Box>
                            </div>
                            <div>
                                <Box padding={{bottom: "s"}}>
                                    <ReportItemsTable setState={setState} state={state} updatingReportItem={updatingReportItem} 
                                     setUpdatingReportItem={setUpdatingReportItem} refreshTable={refreshTable} setReportItem={setReportItem}
                                     editSelectReportItem={editSelectReportItem} setEditSelectReportItem={setEditSelectReportItem}
                                     setRefreshTable={setRefreshTable} report={report as Report} setAddDisabled={setAddDisabled}
                                     setEditDisabled={setEditDisabled} setAddEditInfoDisabled={setAddEditInfoDisabled} 
                                     setSplitPanelOpen={setSplitPanelOpen} setSplitPanelTitle={setSplitPanelTitle}
                                     setAllReportItems={setAllReportItems}/>
                                </Box>
                            </div>
                        </Box>
                </ContentLayout>        
            }
            splitPanel={<SplitPanel header={splitPanelTitle} i18nStrings={splitPanelI18nStrings}>
                    {
                        !addEditInfoDisabled ? (
                            <Alert
                                onDismiss={() => setAddEditInfoDisabled(true)}
                                visible={!addEditInfoDisabled}
                                dismissAriaLabel="Close alert"
                                header="Add/Edit Item">
                                <ul>
                                    <li>Click <b>[Add Item]</b> to add new item in report</li>
                                    <li>Select <b>single</b> item to edit</li>
                                    <li>Select <b>single/multiple</b> items to move</li>
                                </ul>
                            </Alert>
                        ) : (!addDisabled) ? (
                            <ReportItemForm setState={setState} setCustomStateParams={setCustomStateParams} report={report as Report}
                                            setRefreshTable={setRefreshTable} setAddDisabled={setAddDisabled} setEditDisabled={setEditDisabled}
                                            setAddEditInfoDisabled={setAddEditInfoDisabled} allReportItems={allReportItems}/>
                        ) : (!editDisabled) ? (
                            <EditMetricForm updatingReportItem={updatingReportItem} setUpdatingReportItem={setUpdatingReportItem}
                                            setState={setState} report={report as Report} reportItem={!!reportItem && reportItem || null}
                                            setReportItem={setReportItem} setEditSelectReportItem={setEditSelectReportItem}
                                            setAddDisabled={setAddDisabled} setEditDisabled={setEditDisabled} setAddEditInfoDisabled={setAddEditInfoDisabled}
                                            refreshTable={refreshTable} setRefreshTable={setRefreshTable}
                                            setSplitPanelTitle={setSplitPanelTitle} allReportItems={allReportItems}/>
                        ) : ('')
                    }
            </SplitPanel>}
            splitPanelPreferences={{ position: splitPanelPosition }}
            onSplitPanelPreferencesChange={(event) => handleSplitPanelPreferences(event?.detail)}
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={(event) => {
               setSplitPanelOpen(event?.detail?.open);
            }}
            contentType="table"
            ariaLabels={appLayoutLabels}
            toolsHide={true}
        />
    );
}
