import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Tiles from '@amzn/awsui-components-react/polaris/tiles';
import { DELIVERY_METHOD_OPTIONS } from './form-config';
import { useRadio } from '../hooks';

interface ContentDeliveryProps {
  onTopicSelect: (topic: string) => void;
}

// First form section titled 'Distribution content delivery'
export default function ContentDeliveryPanel({ onTopicSelect }: ContentDeliveryProps) {
  const deliveryMethod = useRadio('web');
  return (
    <Container header={<Header>Distribution content delivery</Header>}>
      <FormField
        label="Delivery method"
        info={
          <Link variant="info" onFollow={() => onTopicSelect('deliveryMethod')}>
            Info
          </Link>
        }
        stretch={true}
      >
        <Tiles {...deliveryMethod} items={DELIVERY_METHOD_OPTIONS} />
      </FormField>
    </Container>
  );
}
