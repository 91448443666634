import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
    CollectionPreferences,
    Table,
    Button,
    ExpandableSection,
    Pagination,
    TextFilter,
    Header,
    SpaceBetween
} from '@amzn/awsui-components-react/polaris';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {NON_FINISHED_QUERIES_TABLE_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import React from 'react';
import { NonFinishedQueriesResult } from '../../fondue-api/generated-src';
interface SelectedReportItemsInterface {
    nonFinishedQueries: NonFinishedQueriesResult[],
    nonFinishedSelectedItems: any[],
    setNonFinishedSelectedItems: Dispatch<SetStateAction<any[]>>
}

export default function NonFinishedQuriesTable({nonFinishedQueries, nonFinishedSelectedItems, setNonFinishedSelectedItems}: SelectedReportItemsInterface) {
    const [pageSize, setPageSize] = useState(10);
    const [expanded, setExpanded] = useState(true);
    const { items,  filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        nonFinishedQueries || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can't find a match."
                    />
                )
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: { defaultState: { sortingColumn: { sortingField: "metric_number" } } },
            selection: {}
        }
    );
    function onExpandedChange({detail: expanded}) {
        setExpanded(expanded.expanded)
    }
    function handleSelect(detail) {
        setNonFinishedSelectedItems(detail.selectedItems);
    }
    return (
                <Table 
                    loading = {!nonFinishedQueries}
                    items={items}
                    selectionType="multi"
                    selectedItems={nonFinishedSelectedItems}
                    loadingText='Loading Instances'
                    columnDefinitions={NON_FINISHED_QUERIES_TABLE_DEFINITIONS}
                    resizableColumns
                    isItemDisabled={item => item.allow_backfill === false}
                    header={
                        <>
                        <Header
                            counter={
                                nonFinishedQueries &&
                                (nonFinishedSelectedItems!.length ? `(${nonFinishedSelectedItems!.length}/${nonFinishedQueries.length})` : `(${nonFinishedQueries.length})`)
                            }
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button variant="normal" onClick={() => {setNonFinishedSelectedItems(nonFinishedQueries.filter(item => item.allow_backfill !== false))}}>Select All Items</Button>
                                    <Button variant="normal" onClick={() => setNonFinishedSelectedItems([])}>Deselect All Items</Button>
                                </SpaceBetween>
                            }
                            description={
                                <>
                                  Deleted report items are not allowed to be rerun.
                                </>
                            }
                        >
                           Items
                        </Header>
                    </>
                    }
                    onSelectionChange={({ detail }) => handleSelect(detail)}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}
                        onChange={ event  => {
                            //handlePagination(event)
                            paginationProps.onChange(event);
                        }}
                    />}
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount!)}
                            filteringAriaLabel="Filter records"
                            filteringPlaceholder='Find Metrics'
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            title="Preferences"
                            confirmLabel="Confirm"
                            cancelLabel="Cancel"
                            preferences={{
                                pageSize: pageSize,
                            }}
                            pageSizePreference={{
                                title: "Select page size",
                                    options: [
                                    { value: 10, label: "10 items"},
                                    { value: 20, label: "20 items" },
                                    { value: 50, label: "50 items" },
                                    { value: 100, label: "100 items" }
                                ]
                            }}
                            onConfirm={(event) => setPageSize(event.detail.pageSize as number)}
                        />
                    }
                />
    )
} 