import { Box, Button, Modal, SpaceBetween } from "@amzn/awsui-components-react";

export interface ConfirmationProps {
    visible: boolean,
    description: string,
    header: string,
    onAccept: () => any,
    onCancel: () => any
} 

export default function Confirmation({visible, description, header, ...props}: ConfirmationProps): JSX.Element {
    return (
        <Modal
            id="modal-confirmation"
            visible={visible}
            header={header}
            onDismiss={props.onCancel}
            footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button id="btn-cancel" variant="link" onClick={props.onCancel}>Cancel</Button>
                    <Button id="btn-ok" variant="primary" onClick={props.onAccept}>Ok</Button>
                  </SpaceBetween>
                </Box>
              }>
            {description}
        </Modal>
    );
}