import { ReactNode } from 'react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import Box from '@amzn/awsui-components-react/polaris/box';
import Link from '@amzn/awsui-components-react/polaris/link';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Distribution } from '../../interfaces';

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Distribution>[] = [
  {
    id: 'id',
    header: 'Distribution ID',
    cell: item => <Link href="#/table">{item.id}</Link>,
    minWidth: '180px'
  },
  {
    id: 'domainName',
    cell: item => item.domainName,
    header: 'Domain name',
    minWidth: '160px'
  },
  {
    id: 'deliveryMethod',
    header: 'Delivery method',
    cell: item => item.deliveryMethod,
    minWidth: '100px'
  },
  {
    id: 'priceClass',
    header: 'Price class',
    cell: item => item.priceClass,
    minWidth: '100px'
  },
  {
    id: 'sslCertificate',
    header: 'SSL certificate',
    cell: item => item.sslCertificate,
    minWidth: '100px'
  },
  {
    id: 'origin',
    header: 'Origin',
    cell: item => item.origin,
    minWidth: '100px'
  },
  {
    id: 'status',
    header: 'Status',
    cell: item => item.status,
    minWidth: '100px'
  },
  {
    id: 'state',
    header: 'State',
    cell: item => (
      <StatusIndicator type={item.state === 'Disabled' ? 'error' : 'success'}>{item.state}</StatusIndicator>
    ),
    minWidth: '100px'
  },
  {
    id: 'logging',
    header: 'Logging',
    cell: item => item.logging,
    minWidth: '100px'
  }
];

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
