import {
    SpaceBetween,
    Button,
    Grid, DatePicker, Box, Container, Alert
} from "@amzn/awsui-components-react";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import { ReportItem, QueryResult } from "../../fondue-api/generated-src";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Table from "@amzn/awsui-components-react/polaris/table";
import {DateUtil} from "../../util/DateUtil";
import {getMidwayJwtToken} from "../../auth/MidwayJwtToken";
import FondueApiFactory from "../../fondue-api/FondueApiFactory";
import {Util} from "../../util/Util";
import * as constants from "../../common/constants";


export interface RunQueryProps {

    reportItem?: ReportItem | null;
}

export default function RunQueryComponent({ reportItem }: RunQueryProps) {

    // States
    const [disableRunQueryAndClear, setDisableRunQueryAndClear] = useState(false);
    const [showTimerAndCancel, setShowTimerAndCancel] = useState(false);
    const [loadingRunQuery, setLoadingRunQuery] = useState(false);
    const [reportExecutionDate, setReportExecutionDate] = useState(Util.dateToYMDString(DateUtil.getLastSundayDate()));
    const [elapsedTime, setElapsedTime] = useState("0:00");
    const [disableCancelQuery, setDisableCancelQuery] = useState(false);
    const [loadingCancelQuery, setLoadingCancelQuery] = useState(false);
    const [showRunQueryStatusSuccess, setShowRunQueryStatusSuccess] = useState(false);
    const [showRunQueryStatusFail, setShowRunQueryStatusFail] = useState(false);
    const [showRunQueryStatusStop, setShowRunQueryStatusStop] = useState(false);
    const [runQueryInfoText, setRunQueryInfoText] = useState("");
    const [showResult, setShowResult] = useState(false);
    const [queryResult, setQueryResult] = useState("");

    const [queryResults, setQueryResults] = useState<QueryResult[]>();


    const [runQueryAlertCase, setRunQueryAlertCase] = useState("");

    // For functionality
    let runQueryStartDateTime = new Date(); // Needed to save query start time to calculate elapsed time
    const [runningQueryId, setRunningQueryId] = useState("");

    const querySuccessStatuses = ['FINISHED']
    const queryStopStatuses = ['ABORTED']
    const queryFailStatuses = ['FAILED', 'FORCED TIMEOUT', 'ERROR'] // 'ERROR' is a
    // custom failure response. Please refer 'poll_query()' handler in backend for more info.
    const queryDoneStatuses = [...querySuccessStatuses, ...queryStopStatuses, ...queryFailStatuses]

    const dummyItemID = 'item_id_NA'

    // Methods
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    function setRunQueryAlert(content){

        if (content.includes('permission denied')){
            setRunQueryAlertCase('permission_denied');
        } else if (content.includes('user does not exist')){
            setRunQueryAlertCase('user_does_not_exist');
        } else if (content.includes('no query')){
            setRunQueryAlertCase('no_query');
        } else if (content.includes('Redshift query for Console Datashare on invalid table, please use console_dw_exports schema')){
            setRunQueryAlertCase('console_datashare_issue');
        } else {
            setRunQueryAlertCase('');
        }
    }

    async function pollRunQueryResult(query_id: string, reportItem: ReportItem) : Promise<boolean> {
        const FondueApi = FondueApiFactory();
        await getMidwayJwtToken();

        // Todo: Figure out way to return correct boolean from .then()=>{} or .catch()=>{}. Using this variable for now.
        let returnPollResultStatus = false;

        // Calling getReportItemQueryStatus
        await FondueApi.getReportItemQueryStatus(reportItem.id, query_id, reportItem.type, (reportItem.format) ? reportItem.format : '', reportItem.report_id, (reportItem.name)? reportItem.name: '')
            .then((response) => {

                // Check if query has finished running
                if(queryDoneStatuses.indexOf(response.data['status']) !== -1){
                    // Show result
                    setQueryResult(response.data['result'] || "")
                    setShowResult(true);
                    setRunQueryAlert(response.data['result'] || "");
                    setQueryResults(response.data['results'] || [])

                    if (querySuccessStatuses.indexOf(response.data['status']) !== -1){
                        setShowRunQueryStatusSuccess(true);
                    } else if (queryFailStatuses.indexOf(response.data['status']) !== -1){
                        setShowRunQueryStatusFail(true);
                    } else if (queryStopStatuses.indexOf(response.data['status']) !== -1){
                        setShowRunQueryStatusStop(true);
                    }
                    returnPollResultStatus = true;
                }
            })
            .catch((error)=> {
                // Show result specifically to handle 'ERROR' status message. See comment on
                // 'queryFailStatuses' const above for more info.
                const errorResponse = JSON.parse(error.request.response)

                setQueryResult(errorResponse.message.result);
                setShowResult(true);
                setRunQueryAlert(errorResponse.message.result);

                const errorResult: QueryResult= {
                    name: errorResponse.message.results[0].name! || "",
                    result:  errorResponse.message.results[0].result! || "",
                };

                setQueryResults([errorResult]);

                if (queryFailStatuses.indexOf(errorResponse.message.status) !== -1){
                    setShowRunQueryStatusFail(true);
                } else if (queryStopStatuses.indexOf(errorResponse.message.status) !== -1){
                    setShowRunQueryStatusStop(true);
                }
                // Return 'true' to stop polling loop in 'runQuery()' method
                returnPollResultStatus = true;
            });

        return returnPollResultStatus
    }

    async function runQuery() {

        if (reportItem){

            clearRunQueryStatus();

            if (reportItem.query === ''){
                setRunQueryAlert('no query');
                return;
            }

            // Reset statuses and show 'in-progress' components
            setShowRunQueryStatusSuccess(false);
            setShowRunQueryStatusFail(false);
            setDisableCancelQuery(true);
            setLoadingRunQuery(true);
            setDisableRunQueryAndClear(true);
            setShowTimerAndCancel(true);
            setRunQueryInfoText(constants.SUBMIT_QUERY_INFO_MESSAGE);

            // Initiate runQuery feature time - to be able to show elapsed time
            runQueryStartDateTime = new Date();

            // Update time every 1 sec (to show elapsed time)
            const runQueryElapsedTimeInterval = setInterval(() => {
                setElapsedTime(DateUtil.getElapsedTime(runQueryStartDateTime, new Date()));
            }, 1000);

            await getMidwayJwtToken();
            const FondueApi = FondueApiFactory();

            let finalReportItem = _.cloneDeep(reportItem)

            // Update the '.query' to latest text present in text editor
            finalReportItem.query = Util.replaceAll('IAM_WBR_DT', reportExecutionDate, reportItem.query);


            if (reportItem.id === ''){ // User is running query from 'Add Item' component and does not have item ID
                finalReportItem.id = dummyItemID;
            }

            // Calling runQuery
            await FondueApi.runReportItemQuery(finalReportItem.id, finalReportItem)
                .then(async (response) => {
                    // Submitting query to Redshift was success
                    // Now start polling result
                    setRunQueryInfoText(constants.RUN_QUERY_WAIT_INFO);
                    var gotRunQueryPollResult: boolean = false;
                    setRunningQueryId(response.data['query_id']);
                    setDisableCancelQuery(false);

                    while (!gotRunQueryPollResult) {
                        gotRunQueryPollResult = await pollRunQueryResult(response.data['query_id'], finalReportItem);

                        if (!gotRunQueryPollResult){
                            // Wait for 5 sec before retrying
                            await delay(5000);
                        }
                    }
                })
                .catch((error)=> {
                    const errorResponse = JSON.parse(error.request.response)
                    setRunQueryAlert(errorResponse.message);
                    setShowRunQueryStatusFail(true);
                });

            setRunQueryInfoText('');
            setDisableRunQueryAndClear(false);
            clearInterval(runQueryElapsedTimeInterval);
            setLoadingRunQuery(false);
            setDisableCancelQuery(false);
            setLoadingCancelQuery(false);
            setRunningQueryId('');
            setShowTimerAndCancel(false);
        }
    }

    async function cancelRunQuery() {

        setDisableRunQueryAndClear(true);
        setLoadingRunQuery(false);
        setDisableCancelQuery(true);
        setLoadingCancelQuery(true);
        setRunQueryInfoText(constants.RUN_QUERY_CANCEL_INFO);

        const FondueApi = FondueApiFactory();
        await getMidwayJwtToken();

        // Calling cancelRunQuery
        await FondueApi.cancelReportItemQuery((reportItem)?reportItem.id:'item_id_NA', runningQueryId, (reportItem)?reportItem.report_id:'report_id_NA')
            .then((response) => {
                if(response.data['status']){
                    setRunQueryInfoText(constants.RUN_QUERY_CANCEL_SUCCESS_INFO);
                }
            })
            .catch((error)=> {
                const errorResponse = JSON.parse(error.request.response)
                if (errorResponse.includes('Could not cancel a query that is already in FINISHED state'))
                setRunQueryInfoText(constants.RUN_QUERY_CANCEL_FAILED_INFO);
            });

    }

    function clearRunQueryStatus() {
        setElapsedTime('0:00');
        setShowRunQueryStatusSuccess(false);
        setShowRunQueryStatusFail(false);
        setShowRunQueryStatusStop(false);
        setQueryResult('')
        setShowResult(false);
        setDisableCancelQuery(true);
        setRunQueryInfoText('');
        setRunQueryAlertCase('');
        setShowTimerAndCancel(false);
    }

    function handleRunQueryWBRDatePicker(wbr_date) {
        setReportExecutionDate(wbr_date);
        setShowRunQueryStatusSuccess(false);
        setShowRunQueryStatusFail(false);
        setShowRunQueryStatusStop(false);
    }

    useEffect(() => {

        if(!loadingRunQuery && !loadingCancelQuery){
            // If no RunQuery or CancelQuery is running
            if(reportItem?.type !== 'query_single_result' && reportItem?.type !== 'query_multiple_result'){
                setDisableRunQueryAndClear(true);
            } else {
                setDisableRunQueryAndClear(false);
            }
        }

        return function cleanup() {
            // console.log('[Placeholder] Todo: Check and cancel query if anything is running');
        };
    }, [loadingRunQuery, loadingCancelQuery, reportItem?.type]);


    // TODO - debug the 'key' warning in console when we load 'Add Item' component. This is not observed in 'Edit Metric' component
    return (
        <Container>
        <SpaceBetween size="m">
            <Grid
                id='runQueryGrid'
                disableGutters
                gridDefinition={[
                    { colspan: 10 },
                    { colspan: 2 }
                ]}
            >
                <SpaceBetween id="runQueryElements" direction="horizontal" size="m">
                    <Button id="runquery" variant="normal" iconName="caret-right-filled"
                            onClick={runQuery} disabled={disableRunQueryAndClear} loading={loadingRunQuery}>
                        Run Query
                    </Button>

                    <DatePicker
                        id="runQueryWBRDatePicker"
                        onChange={({detail}) => handleRunQueryWBRDatePicker(detail.value)}
                        value={reportExecutionDate}
                        isDateEnabled={date => date.getDay() == 0 && date < new Date()}
                        nextMonthAriaLabel="Next month"
                        placeholder="YYYY-MM-DD"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                        disabled={disableRunQueryAndClear}
                    />
                    {(showTimerAndCancel) &&
                        <SpaceBetween id="cancelSpaceBetween" direction="horizontal" size="m">
                            <Box id="cancelRQBox" variant="p" padding={{left: "s", right: "s"}}>{elapsedTime}</Box>
                            <Button id="cancelRunQuery" variant="normal" onClick={cancelRunQuery}
                                    disabled={disableCancelQuery} loading={loadingCancelQuery}>
                                Cancel
                            </Button>
                        </SpaceBetween>
                    }
                    {(showRunQueryStatusSuccess) &&  <StatusIndicator id="siComplete">{elapsedTime} - Complete for {reportExecutionDate}</StatusIndicator>}
                    {(showRunQueryStatusFail) && <StatusIndicator id="siFail" type="error">{elapsedTime} - Failed for {reportExecutionDate}</StatusIndicator>}
                    {(showRunQueryStatusStop) && <StatusIndicator id="siStop" type="stopped">{elapsedTime} - Aborted for {reportExecutionDate}</StatusIndicator>}

                </SpaceBetween>

                <Box id="clear-box" float="right">
                    <Button id="clear-run-query" variant="link" disabled={disableRunQueryAndClear} wrapText={false}
                            onClick={clearRunQueryStatus}>Clear</Button>
                </Box>

            </Grid>

            <p><i>{runQueryInfoText}</i></p>
            {
                showResult &&
                (<Table
                    id="run-query-result"
                    columnDefinitions={[
                        {
                            id: "Item name",
                            header: "Report row name",
                            cell: item => item.name || "-",
                            minWidth: '100px',
                            maxWidth: '140px'
                        },
                        {
                            id: "result",
                            header: "Result column",
                            cell: item => item.result || "-",
                            minWidth: '100px',
                            maxWidth: '140px',
                        }
                    ]}
                    resizableColumns
                    wrapLines
                    items={queryResults || []}
                />)
            }
            {
                (runQueryAlertCase === "permission_denied") &&
                <Alert statusIconAriaLabel="Info">
                    To request access to this table, please file a <a
                    href="https://t.corp.amazon.com/create/templates/f195293f-8ae6-46ac-bdfc-3ae9a55e53a9"
                    target="_blank">table access request</a>.
                </Alert>
            }
            {
                (runQueryAlertCase === "user_does_not_exist") &&
                <Alert statusIconAriaLabel="Info">
                    {constants.DW_ACCESS_DENIED_ERROR} Please follow steps in <a href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/#HSetupIAMDWconnection" target="_blank">setup IAMDW connection</a> wiki page.
                </Alert>
            }
            {
                (runQueryAlertCase === "no_query") &&
                <Alert statusIconAriaLabel="Error" type="error">
                    {constants.SQL_QUERY_EMPTY_ERROR}
                </Alert>

            }
            {
                (runQueryAlertCase === "console_datashare_issue") &&
                <Alert statusIconAriaLabel="Error" type="error">
                    Redshift query for Console Datashare on invalid table, please use console_dw_exports schema. Please reference <a href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/ConsoleDWData/" target="_blank"> Console DW Data </a> wiki page
                </Alert>

            }
        </SpaceBetween>
        </Container>
    );
}

