import { Form, SpaceBetween, Button, FormField, Input, Textarea, TextContent, Toggle, TokenGroup, TokenGroupProps, Checkbox, Select, SelectProps, Multiselect, Box } from "@amzn/awsui-components-react";
import React, { useEffect, useState } from "react";
import { ReportItem } from "../../../fondue-api/generated-src";
import { IsDerReportItemType, DerReportItemTypes } from "../../../common/ReportTypes";
import DerFilterForm from "./derfilter/DerFilterForm";
import { derReportItemConfig } from "../../../common/constants";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { FondueApi, Report } from "../../../fondue-api/generated-src";
import FondueApiFactory from "../../../fondue-api/FondueApiFactory";
import { getMidwayJwtToken } from "../../../auth/MidwayJwtToken";

const EmptyFilterError = "Cannot be empty";
const EmptyCustomColumnError = "Select at least one output column"

const PolicyDEROutputTypes = {
    counts: { value: "counts", label: "Policies Count", description: "Number of matching policies" },
    account_counts: { value: "account_counts", label: "Accounts Count", description: "Number of accounts that have matching policies" },
    account_numbers: { value: "account_numbers", label: "Account IDs", disabled: false, description: "Accounts owning matching policies" },
    policy_ids: { value: "policy_ids", label: "Policy Document IDs", disabled: false, description: "Policy document IDs" },
    custom_cols: { value: "custom_cols", label: "Custom Columns", disabled: false, description: "Select custom columns" }
};

const OrganizationPolicyDEROutputTypes = {
    counts: { value: "counts", label: "Policies Count", description: "Number of matching organization policies" },
    account_counts: { value: "account_counts", label: "Accounts Count", description: "Number of accounts that have matching organization policies" },
    organization_counts: { value: "organization_counts", label: "Organization IDs Count", description: "Number of organizations that have matching policies" },
    account_numbers: { value: "account_numbers", label: "Account IDs", disabled: false, description: "Account Ids matching policies" },
    organization_ids: { value: "organization_ids", label: "Organization IDs", disabled: false, description: "Organization Ids matching policies" },
    policy_ids: { value: "policy_ids", label: "Organization Policy IDs", disabled: false, description: "Organization policy IDs" },
    custom_cols: { value: "custom_cols", label: "Custom Columns", disabled: false, description: "Select custom columns" }
};

const CustomColumnsOutputTypes = {
    account_id: { value: "account_id", label: "Account ID"},
    document_id: { value: "document_id", label: "Document ID"},
    arn: { value: "arn", label: "Policy ARN"},
    policy_type: { value: "policy_type", label: "Policy Type"},
    policy_stat: { value: "policy_stat", label: "Policy Statement JSON"}
}

const OrganizationDerCustomColumnsOutputTypes = {
    account_id: { value: "account_id", label: "Account ID"},
    organization_id: { value: "organization_id", label: "Organization ID"},
    policy_id: { value: "policy_id", label: "Policy ID"},
    policy_type: { value: "policy_type", label: "Organization Policy Type"},
    policy_stat: { value: "policy_stat", label: "Policy Statement JSON"}
}

const ManagedPoliciesOptions = {
    "-": { value: "-", label: "All Policies"},
    awsmanaged: { value: "awsmanaged", label: "AWS Managed Policies"},
    customermanaged: { value: "customermanaged", label: "Customer Managed Policies"},
    nonmanaged: {value: "nonmanaged", label: "Inline Policies"},
    trustpolicy: {value: "trustpolicy", label: "Trust Policies"}
}

const OrganizationPoliciesTypes = {
    all: { value: "all", label: "All"},
    service_control_effective_policy: { value: "service_control_effective_policy", label: "Organization Service control effective policies"},
    resource_control_effective_policy: { value: "resource_control_effective_policy", label: "Organization Resource control effective policies"},
}

const PolicyDEREffectTypes = {
    allow: { value: "allow", label: "Allow" },
    deny: { value: "deny", label: "Deny" }
};


export interface DerReportProps {
    clearFields: () => void;
    createReportItem: (reportItemConfig?: {}) => void;
    disabled: boolean;
    metricTypeState: [SelectProps.Option, (any) => void];
    itemNameState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemDescriptionState: [string, React.Dispatch<React.SetStateAction<string>>];
    itemQueryState: [string, React.Dispatch<React.SetStateAction<string>>];
    isEditing?: boolean;
    resetFields?: () => void;
    reportItem?: ReportItem | null;

    enablePolicyFilterForm?: boolean; //Enable or disable filter form
    itemTypeOptions: any;
    submitReportItemLoading: boolean;
}

/**Retrieve filter value for simple filters ({filters:[{value: 'filter-value'}]}) */
function getSimpleFilterValue(filterConfig: string): string | undefined {
    if (filterConfig === undefined) {
        return undefined;
    }

    try {
        const jsonConfig = JSON.parse(filterConfig);
        return jsonConfig?.["filters"]?.[0]?.["value"];
    } catch {
        // backward compatibility with simple value
        return filterConfig;
    }
}

export default function DerReportItemForm({ metricTypeState, itemNameState, itemDescriptionState, itemQueryState, clearFields, createReportItem, disabled, submitReportItemLoading, ...props }: DerReportProps) {
    const [selectionOption, setMetricTypeOption] = metricTypeState;
    const [itemName, setItemName] = itemNameState;
    const [itemDescription, setItemDescription] = itemDescriptionState;
    const [isUserOrganizationDerAuthorized, setisUserOrganizationDerAuthorized] = useState(false);

    let der_config = props.reportItem?.report_item_config?.["der_config"];

    // Initialize to IAM policy scan default and then use effect based on report item type
    const [derfilterConfig, setDerFilterConfig] = useState(derReportItemConfig.der_policy_scan);
    const [useSqlQuery, _] = useState(der_config?.["use_filter_form"] == false);

    const [derOutputType, setDerOutputType] = useState<SelectProps.Option>(PolicyDEROutputTypes[der_config?.["output_type"] ?? "counts"]);

    const [managedPoliciesOption, setManagedPoliciesOption] = useState<SelectProps.Option>(ManagedPoliciesOptions[der_config?.["managed_policy"] ?? "-"]);
    const [organizationDerOutputType, setOrganizationDerOutputType] = useState<SelectProps.Option>(OrganizationPolicyDEROutputTypes[der_config?.["output_type"] ?? "counts"]);
    const [organizationPoliciesOption, setOrganizationPoliciesOption] = useState<SelectProps.Option>(OrganizationPoliciesTypes[der_config?.["organization_policy_type"] ?? "all"]);
    const [useEffectFilter, setUseEffectFilter] = useState(der_config?.["effect"] != undefined);
    const [effect, setEffect] = useState<SelectProps.Option>(PolicyDEREffectTypes[getSimpleFilterValue(der_config?.["effect"]) ?? "allow"]);

    const [useActionFilter, setUseActionFilter] = useState(der_config?.["action"] != undefined);
    const [actionFilter, setActionFilter] = useState<string>(der_config?.["action"] ?? "");
    const [actionError, setActionError] = useState("");

    const [useResourceFilter, setUseResourceFilter] = useState(der_config?.["resource"] != undefined);
    const [resourceFilter, setResourceFilter] = useState<string>(der_config?.["resource"] ?? "");
    const [resourceError, setResourceError] = useState("");

    const [useConditionFilter, setUseConditionFilter] = useState(der_config?.["condition"] != undefined);
    const [conditionFilter, setConditionFilter] = useState<string>(der_config?.["condition"] ?? "");
    const [conditionError, setConditionError] = useState("");

    const [useAccountFilter, setUseAccountFilter] = useState(der_config?.["account"] != undefined);
    const [accountFilter, setAccountFilter] = useState<string>(der_config?.["account"] ?? "");
    const [accountError, setAccountError] = useState("");
    const [useOrganizationFilter, setUseOrganizationFilter] = useState(der_config?.["organization_id"] != undefined);
    const [organizationFilter, setOrganizationFilter] = useState<string>(der_config?.["organization_id"] ?? "");
    const [organizationError, setOrganizationError] = useState("");

    const [usePrincipalFilter, setUsePrincipalFilter] = useState(der_config?.["principal"] != undefined);
    const [principalFilter, setPrincipalFilter] = useState<string>(der_config?.["principal"] ?? "");
    const [principalError, setPrincipalError] = useState("");

    const [useServicePrincipalFilter, setUseServicePrincipalFilter] = useState(!!der_config?.service_principal);
    const [servicePrincipalFilter, setServicePrincipalFilter] = useState<string>(der_config?.service_principal ?? "");
    const [servicePrincipalError, setServicePrincipalError] = useState<string | null>(null);

    let customColumns: string[] = der_config?.["custom_columns"];
    const [customColumnVisible, setCustomColumnVisible] = useState(isCustomColumnsOutput(derOutputType));
    const [organizationDerCustomColumnVisible, setOrganizationDerCustomColumnVisible] = useState(isCustomColumnsOutput(derOutputType));
    const [customColumnType, setCustomColumnType] = useState<readonly SelectProps.Option[]>(customColumns?.map((opt) => CustomColumnsOutputTypes[opt]) ?? []);
    const [customColumnError, setCustomColumnError] = useState("");
    const [organizationDerCustomColumnType, setOrganizationDerCustomColumnType] = useState<readonly SelectProps.Option[]>(customColumns?.map((opt) => OrganizationDerCustomColumnsOutputTypes[opt]) ?? []);
    const [organizationDerCustomColumnError, setOrganizationDerCustomColumnError] = useState("");

    // Setup reference link for DER filters based on report item type selection
    useEffect(() => {
        if (selectionOption.value == "organization_policy_scan") {
            setDerFilterConfig(derReportItemConfig.organization_policy_scan)
        }
        else {
            setDerFilterConfig(derReportItemConfig.der_policy_scan)
        }
        //setMetricTypeOption(getSelectedOption())

    }, [selectionOption, setMetricTypeOption])
    
    // Setup a temporary function to authorize Organization DER (Bindle access to Identity Analytics only)
    async function validateUserOrganizationDerAuthorization(FondueApi: FondueApi) {
        await getMidwayJwtToken();
        const response = await FondueApi.validateReportTypeAuthorization();
        if (response.data.organization_der_authorized) {
            setisUserOrganizationDerAuthorized(true);
        }
    }

    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();
            await validateUserOrganizationDerAuthorization(FondueApi)
        })();
    }, [FondueApiFactory]);
    
    /** Return True if current DER output is 'custom_cols'*/
    function isCustomColumnsOutput(x: OptionDefinition) {
        return x?.value == PolicyDEROutputTypes.custom_cols.value;
    }

    /** OutputType change logic */
    function setOutputType(x: OptionDefinition) {
        if (isCustomColumnsOutput(x)) {
            setCustomColumnVisible(true);
        } else {
            setCustomColumnVisible(false);
        }
        setDerOutputType(x);
    }

    // Setup Organization policy specific functions
    function isOrganizationDerCustomColumnsOutput(x: OptionDefinition) {
        return x.value == OrganizationPolicyDEROutputTypes.custom_cols.value;
    }
    function setOrganizationOutputType(x: OptionDefinition) {
        if (isOrganizationDerCustomColumnsOutput(x)) {
            setOrganizationDerCustomColumnVisible(true);
        } else {
            setOrganizationDerCustomColumnVisible(false);
        }
        setOrganizationDerOutputType(x);
    }

    function getSelectedOption(): SelectProps.Option {
        if (props?.reportItem?.report_item_config?.["der_config"]?.["der_type"]) {
            return props.itemTypeOptions[props.reportItem.report_item_config["der_config"]["der_type"]]
        }

        return selectionOption;
    }

    function clearValidationErrors() {
        setActionError("");
        setResourceError("");
        setConditionError("");
        setAccountError("");
        setPrincipalError("");
        setServicePrincipalError("");
        setCustomColumnError("");
    }

    function isFilterEmpty(filter): boolean {
        return (filter == "" || JSON.parse(filter).filters.length == 0);
    }

    function validatePolicyForm(): boolean {
        let validationPass = true;
        clearValidationErrors();
        if (useActionFilter && isFilterEmpty(actionFilter)) {
            setActionError(EmptyFilterError);
            validationPass = false;
        }

        if (useResourceFilter && isFilterEmpty(resourceFilter)) {
            setResourceError(EmptyFilterError);
            validationPass = false;
        }

        if (useConditionFilter && isFilterEmpty(conditionFilter)) {
            setConditionError(EmptyFilterError);
            validationPass = false;
        }

        if (useAccountFilter && isFilterEmpty(accountFilter)) {
            setAccountError(EmptyFilterError);
            validationPass = false;
        }

        if (usePrincipalFilter && isFilterEmpty(principalFilter)) {
            setPrincipalError(EmptyFilterError);
            validationPass = false;
        }

        if (useServicePrincipalFilter && isFilterEmpty(servicePrincipalFilter)) {
            setServicePrincipalError(EmptyFilterError);
            validationPass = false;
        }

        if (isCustomColumnsOutput(derOutputType) && customColumnType.length == 0) {
            setCustomColumnError(EmptyCustomColumnError);
            validationPass = false;
        }

        if (isOrganizationDerCustomColumnsOutput(organizationDerOutputType) && organizationDerCustomColumnType.length == 0) {
            setOrganizationDerCustomColumnError(EmptyCustomColumnError);
            validationPass = false;
        }

        return validationPass;
    }

    function createDerReportItem() {
        if (validatePolicyForm()) {
            der_config = createReportItem(buildReportItemConfig());
        }
    }

    function buildReportItemConfig(): any {
        der_config = {
            use_filter_form: !useSqlQuery,
            der_type: props.isEditing ? getSelectedOption().value : selectionOption.value // Always set der_type from config when editing
        }

        // Add IAM der output in der config if the option selected
        if (selectionOption.value == "der_policy_scan" || getSelectedOption()?.value == "der_policy_scan" ) {
            der_config["output_type"] = derOutputType.value
            der_config["custom_columns"] = customColumnType.map((opt) => opt.value)
        }

        // Add Organization der output in der config if the option selected
        if (selectionOption.value == "organization_policy_scan" || getSelectedOption().value == "organization_policy_scan") {
            der_config["output_type"] = organizationDerOutputType.value
            der_config["custom_columns"] = organizationDerCustomColumnType.map((opt) => opt.value)
        }

        if (organizationPoliciesOption.value != "all") {
            der_config["organization_policy_type"] = organizationPoliciesOption.value
        }

        if (managedPoliciesOption.value != "-") {
            der_config["managed_policy"] = managedPoliciesOption.value
        }

        if (useEffectFilter) {
            der_config["effect"] = JSON.stringify({filters: [{value: effect.value}]});
        }

        if (useActionFilter) {
            der_config["action"] = actionFilter;
        }

        if (useResourceFilter) {
            der_config["resource"] = resourceFilter;
        }

        if (useConditionFilter) {
            der_config["condition"] = conditionFilter;
        }

        if (usePrincipalFilter) {
            der_config["principal"] = principalFilter
        }

        if (useServicePrincipalFilter) {
            der_config["service_principal"] = servicePrincipalFilter
        }

        if (useAccountFilter) {
            der_config["account"] = accountFilter;
        }

        if (useOrganizationFilter) {
            der_config["organization_id"] = organizationFilter;
        }

        return { "der_config": der_config };
    }

    // Specify IAM policy specific form fields
    const iam_policy_fields = (
        <>
            <FormField id="managed-policies-option" label="Managed Policies Option" description={
                <span>See more information on managed and inline policies <a title="Managed policies and inline policies" href="https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies_managed-vs-inline.html" target="_blank">here</a></span>
            }>
                <Select options={Object.keys(ManagedPoliciesOptions).map((key) => ManagedPoliciesOptions[key])} selectedOption={managedPoliciesOption}
                            onChange={({detail}) => setManagedPoliciesOption(detail.selectedOption)}/>
            </FormField>
            <FormField id="output-type" label="Output Columns">
                <Select selectedOption={derOutputType}
                        onChange={({ detail }) => setOutputType(detail.selectedOption)}
                        options={Object.keys(PolicyDEROutputTypes).map((key) => PolicyDEROutputTypes[key])} />
            </FormField>
            {customColumnVisible && <FormField id="custom-columns" errorText={customColumnError}>
                    <Multiselect selectedOptions={customColumnType}
                                onChange={({detail}) => setCustomColumnType(detail.selectedOptions)}
                                placeholder="Choose output columns"
                                options={Object.keys(CustomColumnsOutputTypes).map((key) => CustomColumnsOutputTypes[key])}
                    />
            </FormField>}
        </>
        );

    // Specify Organization policy specific form fields
    const organization_policy_fields = (
        <>
            <FormField id="organization-policy-type" label="Organization Policy type" description={
                <span>See more information on Organization policies <a title="Organization Service control and Resource control effective policies" href="https://docs.aws.amazon.com/organizations/latest/userguide/orgs_manage_policies_scps.html" target="_blank">here</a></span>
            }>
                <Select options={Object.keys(OrganizationPoliciesTypes).map((key) => OrganizationPoliciesTypes[key])} selectedOption={organizationPoliciesOption}
                            onChange={({detail}) => setOrganizationPoliciesOption(detail.selectedOption)}/>
            </FormField>
            <FormField id="output-type" label="Output Columns">
                    <Select selectedOption={organizationDerOutputType}
                        onChange={({ detail }) => setOrganizationOutputType(detail.selectedOption)}
                        options={Object.keys(OrganizationPolicyDEROutputTypes).map((key) => OrganizationPolicyDEROutputTypes[key])} />
            </FormField>
            {organizationDerCustomColumnVisible && <FormField id="custom-columns" errorText={organizationDerCustomColumnError}>
                    <Multiselect selectedOptions={organizationDerCustomColumnType}
                                onChange={({detail}) => setOrganizationDerCustomColumnType(detail.selectedOptions)}
                                placeholder="Choose output columns"
                                options={Object.keys(OrganizationDerCustomColumnsOutputTypes).map((key) => OrganizationDerCustomColumnsOutputTypes[key])}
                    />
            </FormField>}
            <Box>
                <FormField id="use-organization-filter">
                    <Checkbox checked={useOrganizationFilter} onChange={({ detail }) => { setUseOrganizationFilter(detail.checked) }}
                            description={
                                <React.Fragment>
                                    Filter based on Organization id
                                </React.Fragment>
                            }>
                            Organization id Filter
                    </Checkbox>
                </FormField>
                {useOrganizationFilter && <FormField id="organization-filter" constraintText="(Use % as wildcard)" errorText={organizationError}>
                    <DerFilterForm field="organization_id" filters={organizationFilter} onUpdate={(value) => setOrganizationFilter(value)} />
                </FormField>}
            </Box>
        </>
        );

    return (
        <Form actions={
            <SpaceBetween direction="horizontal" size="xs">
                <Button id="cancel" variant="normal" onClick={clearFields} disabled={disabled}>
                    Cancel
                </Button>
                {
                    props.isEditing === true
                    && <Button id="reset" variant="normal" onClick={props.resetFields ?? (() => { })} disabled={disabled}>
                        Reset
                    </Button>
                }
                <Button id="submit" variant="primary" loading={submitReportItemLoading} onClick={() => createDerReportItem()} disabled={disabled}>
                    Submit
                </Button>
            </SpaceBetween>
        }>
        <SpaceBetween size='xs'>
            <FormField label="Type">
                <Select
                    disabled={!isUserOrganizationDerAuthorized && IsDerReportItemType(props.reportItem! ?? selectionOption.value)}
                    controlId="metrictype"
                    selectedOption={getSelectedOption()}
                    placeholder="Select item type"
                    options={Object.keys(props.itemTypeOptions).map((itemType) => props.itemTypeOptions[itemType]).filter((option) => isUserOrganizationDerAuthorized || !IsDerReportItemType(option.value))}
                    onChange={event => {
                        setMetricTypeOption(event);
                    }}
                />
            </FormField>
            <FormField
                id="name"
                label="Item Name" description="Name of item">
                <Input
                    value={itemName}
                    onChange={event =>
                        setItemName(event.detail.value)
                    }
                />
            </FormField>
            <FormField
                id="description"
                label={
                    <span>
                        Item Description <i> - optional </i>{" "}
                    </span>
                }
            >
                <Textarea
                    value={itemDescription}
                    rows={3}
                    onChange={({ detail }) =>
                        setItemDescription(detail.value)}
                />
            </FormField>

            {/* Populate DER form fields based on the policy scan you choose for add item option */}
            { selectionOption.value == DerReportItemTypes.der_policy_scan.value ? iam_policy_fields : null}
            { selectionOption.value == DerReportItemTypes.organization_policy_scan.value ? organization_policy_fields : null}
            {/* Populate DER form fields based on the policy scan you choose for edit report item check box option */}
            { props.reportItem?.report_item_config?.["der_config"]?.["der_type"] == DerReportItemTypes.der_policy_scan.value ? iam_policy_fields : null}
            { props.reportItem?.report_item_config?.["der_config"]?.["der_type"] == DerReportItemTypes.organization_policy_scan.value ? organization_policy_fields : null}

            <React.Fragment>
                <Box>
                <FormField id="use-effect-filter">
                    <Checkbox checked={useEffectFilter} onChange={({ detail }) => { setUseEffectFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href={derfilterConfig["filterConfig"]["effect_doc"]}>policy effects</a>
                            </React.Fragment>
                        }>
                        Effect Filter
                    </Checkbox>
                </FormField>
                {useEffectFilter && <FormField id="effect-filter">
                    <Select disabled={!useEffectFilter}
                        selectedOption={effect}
                        onChange={({ detail }) => { setEffect(detail.selectedOption) }}
                        options={Object.keys(PolicyDEREffectTypes).map((key) => PolicyDEREffectTypes[key])}
                        selectedAriaLabel="Selected" />
                </FormField>}
                <FormField id="use-action-filter">
                    <Checkbox checked={useActionFilter} onChange={({ detail }) => { setUseActionFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href={derfilterConfig["filterConfig"]["action_doc"]}>policy actions</a>
                            </React.Fragment>
                        }>
                        Action Filter
                    </Checkbox>
                </FormField>
                {useActionFilter && <FormField id="action-filter" constraintText="(Use % as wildcard)" errorText={actionError}>
                    <DerFilterForm field="action" filters={actionFilter} onUpdate={(value) => setActionFilter(value)} />
                </FormField>}
                <FormField id="use-resource-filter">
                    <Checkbox checked={useResourceFilter} onChange={({ detail }) => { setUseResourceFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href={derfilterConfig["filterConfig"]["resource_doc"]}>policy resources</a>
                            </React.Fragment>
                        }>
                        Resource Filter
                    </Checkbox>
                </FormField>
                {useResourceFilter && <FormField id="resource-filter" constraintText="(Use % as wildcard)" errorText={resourceError}>
                    <DerFilterForm field="resource" filters={resourceFilter} onUpdate={(value) => setResourceFilter(value)} />
                </FormField>}
                <FormField id="use-condition-filter">
                    <Checkbox checked={useConditionFilter} onChange={({ detail }) => { setUseConditionFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href={derfilterConfig["filterConfig"]["condition_doc"]}>policy conditions</a>
                            </React.Fragment>
                        }>
                        Condition Filter
                    </Checkbox>
                </FormField>
                {useConditionFilter &&
                    <FormField id="condition-filter" constraintText="(Use % as wildcard)" errorText={conditionError}>
                        <DerFilterForm field="condition" filters={conditionFilter} onUpdate={(value) => setConditionFilter(value)} />
                    </FormField>}
                <FormField id="use-principal-filter">
                    <Checkbox checked={usePrincipalFilter} onChange={({ detail }) => { setUsePrincipalFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on <a target="_blank" href={derfilterConfig["filterConfig"]["principal_doc"]}>policy principal conditions</a>
                            </React.Fragment>
                        }>
                        Policy Principal Filter
                    </Checkbox>
                </FormField>
                {usePrincipalFilter && <FormField id="principal-filter" constraintText="(Use % as wildcard)" errorText={principalError}>
                    <DerFilterForm field="principal" filters={principalFilter} onUpdate={(value) => setPrincipalFilter(value)} />
                </FormField>}

                <FormField>
                    <Checkbox
                        checked={useServicePrincipalFilter}
                        onChange={({ detail }) => setUseServicePrincipalFilter(detail.checked)}
                        description={
                            <>
                                Filter based on{" "}
                                <a
                                    target="_blank"
                                    href="https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_elements_principal.html#principal-services"
                                >
                                    service principal conditions
                                </a>
                            </>
                        }
                    >
                        Service Principal Filter
                    </Checkbox>
                </FormField>

                {useServicePrincipalFilter && (
                    <FormField constraintText="(Use % as wildcard)" errorText={servicePrincipalError}>
                        <DerFilterForm
                            field="service_principal"
                            filters={servicePrincipalFilter}
                            onUpdate={(value) => setServicePrincipalFilter(value)}
                        />
                    </FormField>
                )}

                <FormField id="use-account-filter">
                    <Checkbox checked={useAccountFilter} onChange={({ detail }) => { setUseAccountFilter(detail.checked) }}
                        description={
                            <React.Fragment>
                                Filter based on owning account's id
                            </React.Fragment>
                        }>
                        Account Filter
                    </Checkbox>
                </FormField>
                {useAccountFilter && <FormField id="account-filter" constraintText="(Use % as wildcard)" errorText={accountError}>
                    <DerFilterForm field="account" filters={accountFilter} onUpdate={(value) => setAccountFilter(value)} />
                </FormField>}
                </Box>
            </React.Fragment>
            
        </SpaceBetween>
        </Form>
    );
}