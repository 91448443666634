import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';

export default function ToolsPanel() {
    return (
        <HelpPanel
            header={<h2>CloudFront</h2>}
            footer={
                <ul>
                    <li>
                        <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html">
                            What is Amazon CloudFront?
                        </a>
                    </li>
                    <li>
                        <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html">
                            Getting started
                        </a>
                    </li>
                    <li>
                        <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
                            Working with distributions
                        </a>
                    </li>
                </ul>
            }
        />
    );
}
