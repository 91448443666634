import React, { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import { Distribution } from '../../interfaces';

export default function CollectionHooksTable() {
  const [allItems, setAllItems] = useState<Distribution[]>();

  useEffect(() => {
    (async () => setAllItems((await import('../../data/distributions.json')).default as Distribution[]))();
  });
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No instances"
            subtitle="No instances to display."
            action={<Button>Create instance</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        )
      },
      pagination: {
        pageSize: 10
      },
      sorting: {},
      selection: {}
    }
  );

  const { selectedItems } = collectionProps;
  return (
    <Table
      {...collectionProps}
      loading={!allItems}
      loadingText="Loading instances"
      selectionType="multi"
      header={
        <Header
          counter={
            allItems &&
            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
          }
        >
          Instances
        </Header>
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount!)}
          filteringAriaLabel="Filter instances"
        />
      }
    />
  );
}
