import { useCallback, useEffect, useState } from "react";
import { ReportExecution } from "../../../fondue-api/generated-src";
import { DateUtil } from "../../../util/DateUtil";

interface ExecutionDurationProps {
    execution: ReportExecution;
}

/**
 * Component for displaying the duration of the provided execution object.
 * If the execution is still running, the timer will refresh each second to display the current duration.
 * Once we get a response back from the server that the execution is completed, the timer will stop.
 */
export const ExecutionDuration = ({ execution }: ExecutionDurationProps) => {
    const { start_datetime: start, end_datetime: end } = execution;
    const [duration, setDuration] = useState(() => getCurrentDuration(start, end));

    const updateDuration = useCallback(() => {
        setDuration(getCurrentDuration(start, end));
    }, [setDuration, start, end]);

    // Enable the timer only if the `end_datetime` is not yet available.
    const enableTimer = !end;

    useEffect(() => {
        if (enableTimer) {
            const id = setInterval(updateDuration, 1000);
            return () => clearInterval(id);
        }

        updateDuration();
    }, [enableTimer, updateDuration]);

    return (
        <span role="timer" aria-label="Report execution duration timer">
            {duration}
        </span>
    );
};

const getCurrentDuration = (start?: string, end?: string) => {
    // If `end_datetime` is not available, we will use the current date and time to calculate the duration.
    // The date string needs to be in UTC ISO format since the back-end uses the same format, hence the `replace("Z", "")` call.
    const endDate = end || new Date().toISOString().replace("Z", "");
    // Check if the date string matches the yyyy-mm-dd:hh:mm:ss format which have issues with firefox
    const regex = /^\d{4}-\d{2}-\d{2}:\d{2}:\d{2}:\d{2}$/;
    if (typeof start === 'string' && regex.test(start)) {
        // we need to convert the format into yyyy-mm-dd hh:mm:ss
        start = start.replace(/:/, ' ').replace(/(\d{2}) (\d{2}:\d{2}:\d{2})/, '$1 $2');
    }
    return DateUtil.getDateTimeDifferenceInDHMSFormat(start, endDate);
};
