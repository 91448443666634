import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';

const topics: Record<string, React.ReactNode> = {
  // List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
  main: (
    <HelpPanel
      header={<h2>CloudFront</h2>}
      footer={
        <ul>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html">
              What is Amazon CloudFront?
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html">
              Getting started
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
              Working with distributions
            </a>
          </li>
        </ul>
      }
    />
  ),
  deliveryMethod: (
    <HelpPanel header={<h2>Delivery Method</h2>}>
      <h4>Web</h4>
      <p>Create a web distribution if you want to:</p>
      <p>
        Speed up distribution of static and dynamic content, for example .html, .css, .php and graphics files.
        Distribute media files using HTTP or HTTPS.
      </p>
      <p>Add, update or delete objects and submit data from web forms.</p>
      <p>Use live streaming to stream an event in real time.</p>
      <p>
        You store your files in an origin - either an Amazon S3 bucket or a web server. After you create the
        distribution, you can add more origins to the distributions.
      </p>
      <h4>RTMP</h4>
      <p>
        Create an RTMP distribution to speed up distribution of your streaming media files using Adobe Flash Media
        Server's RTMP protocol. An RTMP distribution allows an end user to begin playing a media file before the file
        has finished downloading from a CloudFront edge location. Note the following:
      </p>
      <p>To create an RTMP distribution, you must store the media files in an Amazon S3 bucket.</p>
      <p>To use CloudFront live streaming, create a web distribution.</p>
    </HelpPanel>
  ),
  priceClass: (
    <HelpPanel header={<h2>Price class</h2>}>
      <p>
        Select the price class associated with the maximum price that you want to pay for CloudFront service. If you
        select a price class other than All, some of your users may experience higher latency.
      </p>
    </HelpPanel>
  ),
  alternativeDomains: (
    <HelpPanel header={<h2>Alternate domain names (CNAMEs)</h2>}>
      <p>
        You must list any custom domain names (for example, www.example.com) that you use in addition to the CloudFront
        domain name (for example, d1234.cloudfront.net) for the URLs for your files.
      </p>
      <p>
        Specify up to 100 CNAMEs separated with commas or put each on a new line. You also must create a CNAME record
        with your DNS service to route queries for www.example.com to d1234.cloudfront.net. For more information, see
        the <a href="">Help</a>.
      </p>
    </HelpPanel>
  ),
  sslCertificate: (
    <HelpPanel header={<h2>SSL certificate</h2>}>
      <h4>Default CloudFront SSL certificate</h4>
      <p>
        Choose this option if you want your users to use HTTPS or HTTP to access your content with the CloudFront domain
        name (such as https://d111111abcdef8.cloudfront.net/logo.jpg).
      </p>
      <p>
        Important: If you choose this option, CloudFront requires that browsers or devices support TLSv1 or later to
        access your content.
      </p>
      <h4>Custom SSL certificate</h4>
      <p>
        Choose this option if you want your users to access your content by using an alternate domain name, such as
        https://www.example.com/logo.jpg.
      </p>
      <p>
        You can use a certificate stored in AWS Certificate Manager (ACM) in the US East (N. Virginia) Region, or you
        can use a certificate stored in IAM.
      </p>
    </HelpPanel>
  ),
  contentOrigin: (
    <HelpPanel header={<h2>Content origin</h2>}>
      <p>
        Specify the domain name for your origin - the Amazon S3 bucket or web server from which you want CloudFront to
        get your web content. The dropdown list enumerates the AWS resources associated with the current AWS account.
      </p>
      <p>
        To use a resource from a different AWS account, type the domain name of the resource. For example, for an Amazon
        S3 bucket, type the name in the format bucketname.s3.amazonaws.com. The files in your origin must be publicly
        readable.
      </p>
    </HelpPanel>
  )
};

export default topics;
