/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI Fondue API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApprovalSyncRequest
 */
export interface ApprovalSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof ApprovalSyncRequest
     */
    'report_id': string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalSyncRequest
     */
    'execution_id': string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalSyncRequest
     */
    'classic_report_id': string;
}
/**
 * 
 * @export
 * @interface DisableReportItemRequest
 */
export interface DisableReportItemRequest {
    /**
     * 
     * @type {string}
     * @memberof DisableReportItemRequest
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GenMVPRequest
 */
export interface GenMVPRequest {
    /**
     * 
     * @type {string}
     * @memberof GenMVPRequest
     */
    'execution_id': string;
    /**
     * 
     * @type {string}
     * @memberof GenMVPRequest
     */
    'report_id': string;
}
/**
 * 
 * @export
 * @interface GenMVPResponse
 */
export interface GenMVPResponse {
    /**
     * 
     * @type {string}
     * @memberof GenMVPResponse
     */
    'ticket_link'?: string;
}
/**
 * 
 * @export
 * @interface GenerateReportRequest
 */
export interface GenerateReportRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateReportRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateReportRequest
     */
    'wbr_date'?: string;
}
/**
 * 
 * @export
 * @interface GenerateReportResponse
 */
export interface GenerateReportResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateReportResponse
     */
    'executionArn'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateReportResponse
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface GetExecutionMetadataResponse
 */
export interface GetExecutionMetadataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetExecutionMetadataResponse
     */
    'customer_data'?: boolean;
}
/**
 * 
 * @export
 * @interface GetSimMetadataResponse
 */
export interface GetSimMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSimMetadataResponse
     */
    'ticket_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSimMetadataResponse
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSimMetadataResponse
     */
    'cti'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSimMetadataResponse
     */
    'severity'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSimMetadataResponse
     */
    'resolver_groups'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetSimMetadataResponse
     */
    'sec_ops'?: boolean;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface NonFinishedQueriesResponse
 */
export interface NonFinishedQueriesResponse {
    /**
     * 
     * @type {NonFinishedQueriesResult}
     * @memberof NonFinishedQueriesResponse
     */
    'results'?: NonFinishedQueriesResult;
}
/**
 * 
 * @export
 * @interface NonFinishedQueriesResult
 */
export interface NonFinishedQueriesResult {
    /**
     * 
     * @type {string}
     * @memberof NonFinishedQueriesResult
     */
    'execution_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NonFinishedQueriesResult
     */
    'item_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NonFinishedQueriesResult
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NonFinishedQueriesResult
     */
    'result'?: string;
    /**
     * 
     * @type {string}
     * @memberof NonFinishedQueriesResult
     */
    'status'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NonFinishedQueriesResult
     */
    'allow_backfill'?: boolean;
}
/**
 * 
 * @export
 * @interface PastAvailableReportDateRangeResponse
 */
export interface PastAvailableReportDateRangeResponse {
    /**
     * 
     * @type {object}
     * @memberof PastAvailableReportDateRangeResponse
     */
    'available_dates'?: object;
}
/**
 * 
 * @export
 * @interface QueryResult
 */
export interface QueryResult {
    /**
     * 
     * @type {string}
     * @memberof QueryResult
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryResult
     */
    'result'?: string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'project'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'rule_schedule'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Report
     */
    'report_item_order': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'permissions'?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'bindle': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'report_type'?: string;
}
/**
 * 
 * @export
 * @interface ReportApprovalRequest
 */
export interface ReportApprovalRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'report_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'partitions': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'reference_ticket_link': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'execution_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'approver_alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'appsec_approval_ticket'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'group': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'approver': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalRequest
     */
    'approval_type': string;
}
/**
 * 
 * @export
 * @interface ReportApprovalResponse
 */
export interface ReportApprovalResponse {
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalResponse
     */
    'approval_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportApprovalResponse
     */
    'approval_ticket_link'?: string;
}
/**
 * 
 * @export
 * @interface ReportExecution
 */
export interface ReportExecution {
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'report_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'wbr_date': string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'execution_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'items_included'?: string;
    /**
     * 
     * @type {object}
     * @memberof ReportExecution
     */
    'query_status_count'?: object;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'execution_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'end_datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'start_datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'callback_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'app_sec_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'app_sec_link'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportExecution
     */
    'failure_reason'?: string;
}
/**
 * 
 * @export
 * @interface ReportExecutionsRequest
 */
export interface ReportExecutionsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportExecutionsRequest
     */
    'wbr_date_range': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportExecutionsRequest
     */
    'backfill'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportExecutionsRequest
     */
    'selected_report_items'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReportItem
 */
export interface ReportItem {
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'report_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'query'?: string;
    /**
     * 
     * @type {ReportItemReportItemConfig}
     * @memberof ReportItem
     */
    'report_item_config'?: ReportItemReportItemConfig;
    /**
     * 
     * @type {number}
     * @memberof ReportItem
     */
    'metric_number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItem
     */
    'bindle': string;
}
/**
 * 
 * @export
 * @interface ReportItemReportItemConfig
 */
export interface ReportItemReportItemConfig {
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'format'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'wow'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'dod'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'yoy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportItemReportItemConfig
     */
    'ytd'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportItemReportItemConfig
     */
    'arr'?: number;
}
/**
 * 
 * @export
 * @interface ReportSchedule
 */
export interface ReportSchedule {
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'report_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'ScheduleExpression': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSchedule
     */
    'State': string;
}
/**
 * 
 * @export
 * @interface RetrieveCFUrlRequest
 */
export interface RetrieveCFUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof RetrieveCFUrlRequest
     */
    'report_id'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveCFUrlResponse
 */
export interface RetrieveCFUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof RetrieveCFUrlResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveDatadumpRequest
 */
export interface RetrieveDatadumpRequest {
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpRequest
     */
    'execution_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpRequest
     */
    'reportName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpRequest
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpRequest
     */
    'format'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveDatadumpResponse
 */
export interface RetrieveDatadumpResponse {
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpResponse
     */
    'url'?: string;
    /**
     * 
     * @type {RetrieveDatadumpResponseCaz}
     * @memberof RetrieveDatadumpResponse
     */
    'caz'?: RetrieveDatadumpResponseCaz;
}
/**
 * 
 * @export
 * @interface RetrieveDatadumpResponseCaz
 */
export interface RetrieveDatadumpResponseCaz {
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpResponseCaz
     */
    'authorization_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpResponseCaz
     */
    'deny_reason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RetrieveDatadumpResponseCaz
     */
    'is_authorized'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RetrieveDatadumpResponseCaz
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveReportRequest
 */
export interface RetrieveReportRequest {
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'reportName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'fileType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportRequest
     */
    'executionId'?: string;
}
/**
 * 
 * @export
 * @interface RetrieveReportResponse
 */
export interface RetrieveReportResponse {
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportResponse
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof RetrieveReportResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SubmitTaskTokenRequest
 */
export interface SubmitTaskTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitTaskTokenRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitTaskTokenRequest
     */
    'execution_id'?: string;
}
/**
 * 
 * @export
 * @interface SubmitTaskTokenResponse
 */
export interface SubmitTaskTokenResponse {
    /**
     * 
     * @type {RetrieveDatadumpResponseCaz}
     * @memberof SubmitTaskTokenResponse
     */
    'caz'?: RetrieveDatadumpResponseCaz;
}
/**
 * 
 * @export
 * @interface UserIsMemberOfGroupResponse
 */
export interface UserIsMemberOfGroupResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserIsMemberOfGroupResponse
     */
    'valid_user'?: boolean;
}
/**
 * 
 * @export
 * @interface ValidatePermissionRequest
 */
export interface ValidatePermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionRequest
     */
    'action': string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionRequest
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionRequest
     */
    'item_id'?: string;
}
/**
 * 
 * @export
 * @interface ValidatePermissionResponse
 */
export interface ValidatePermissionResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionResponse
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionResponse
     */
    'report_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidatePermissionResponse
     */
    'item_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValidatePermissionResponse
     */
    'authorized'?: boolean;
}
/**
 * 
 * @export
 * @interface ValidateReportTypeAuthorizationResponse
 */
export interface ValidateReportTypeAuthorizationResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidateReportTypeAuthorizationResponse
     */
    'user'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ValidateReportTypeAuthorizationResponse
     */
    'der_authorized'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ValidateReportTypeAuthorizationResponse
     */
    'wbr_authorized'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ValidateReportTypeAuthorizationResponse
     */
    'organization_der_authorized'?: boolean;
}

/**
 * FondueApi - axios parameter creator
 * @export
 */
export const FondueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancels a generate report request
         * @param {string} reportId The id of the report to cancel
         * @param {ReportExecution} reportExecution A Report Id and WBR date to cancel execution for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReportExecution: async (reportId: string, reportExecution: ReportExecution, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('cancelReportExecution', 'reportId', reportId)
            // verify required parameter 'reportExecution' is not null or undefined
            assertParamExists('cancelReportExecution', 'reportExecution', reportExecution)
            const localVarPath = `/report_executions/{reportId}/cancel`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportExecution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels running query
         * @param {string} reportItemId The id of the report item to run query for
         * @param {string} redshiftQueryId ID of query running in Redshift Data API
         * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReportItemQuery: async (reportItemId: string, redshiftQueryId: string, reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('cancelReportItemQuery', 'reportItemId', reportItemId)
            // verify required parameter 'redshiftQueryId' is not null or undefined
            assertParamExists('cancelReportItemQuery', 'redshiftQueryId', redshiftQueryId)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('cancelReportItemQuery', 'reportId', reportId)
            const localVarPath = `/report_items/{reportItemId}/cancel`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redshiftQueryId !== undefined) {
                localVarQueryParameter['redshiftQueryId'] = redshiftQueryId;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a report
         * @param {Report} report A *Report* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (report: Report, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'report' is not null or undefined
            assertParamExists('createReport', 'report', report)
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(report, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a report item
         * @param {ReportItem} reportItem A *Report Item* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportItem: async (reportItem: ReportItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItem' is not null or undefined
            assertParamExists('createReportItem', 'reportItem', reportItem)
            const localVarPath = `/report_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReport', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Report Item by id
         * @param {string} reportItemId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportItem: async (reportItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('deleteReportItem', 'reportItemId', reportItemId)
            const localVarPath = `/report_items/{reportItemId}`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disables specific Report Item
         * @param {string} reportItemId The id of the report item to disable
         * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportItem: async (reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('disableReportItem', 'reportItemId', reportItemId)
            // verify required parameter 'disableReportItemRequest' is not null or undefined
            assertParamExists('disableReportItem', 'disableReportItemRequest', disableReportItemRequest)
            const localVarPath = `/report_items/{reportItemId}/disable`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(disableReportItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportSchedule: async (reportScheduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('disableReportSchedule', 'reportScheduleId', reportScheduleId)
            const localVarPath = `/disable_report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportItemId The id of the report item to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportItem: async (reportItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('enableReportItem', 'reportItemId', reportItemId)
            const localVarPath = `/report_items/{reportItemId}/enable`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportSchedule: async (reportScheduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('enableReportSchedule', 'reportScheduleId', reportScheduleId)
            const localVarPath = `/enable_report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportId The id of the report to schedule executions for
         * @param {ReportExecutionsRequest} reportExecutionsRequest List of WBR dates to request execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueueReportExecution: async (reportId: string, reportExecutionsRequest: ReportExecutionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('enqueueReportExecution', 'reportId', reportId)
            // verify required parameter 'reportExecutionsRequest' is not null or undefined
            assertParamExists('enqueueReportExecution', 'reportExecutionsRequest', reportExecutionsRequest)
            const localVarPath = `/report_executions/{reportId}/enqueue`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportExecutionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate IAM Policy DER MVP query, and create a related SIM ticket
         * @param {GenMVPRequest} genMVPRequest Parameters for generating MVP queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genMVP: async (genMVPRequest: GenMVPRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genMVPRequest' is not null or undefined
            assertParamExists('genMVP', 'genMVPRequest', genMVPRequest)
            const localVarPath = `/generate_mvp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genMVPRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportId The id of the report to generate
         * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportSchedule: async (reportId: string, generateReportRequest: GenerateReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('generateReportSchedule', 'reportId', reportId)
            // verify required parameter 'generateReportRequest' is not null or undefined
            assertParamExists('generateReportSchedule', 'generateReportRequest', generateReportRequest)
            const localVarPath = `/generate_report/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get execution metadata
         * @param {string} id The id of the report
         * @param {string} executionId The id of the execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutionMetadata: async (id: string, executionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExecutionMetadata', 'id', id)
            // verify required parameter 'executionId' is not null or undefined
            assertParamExists('getExecutionMetadata', 'executionId', executionId)
            const localVarPath = `/reports/{id}/{executionId}/metadata`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"executionId"}}`, encodeURIComponent(String(executionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all non finished queries of a given report and wbr date.
         * @param {string} reportId The id of the report to get executions for
         * @param {string} wbrDate The wbr date for which the non-finshed queries are needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonFinishedQueries: async (reportId: string, wbrDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getNonFinishedQueries', 'reportId', reportId)
            // verify required parameter 'wbrDate' is not null or undefined
            assertParamExists('getNonFinishedQueries', 'wbrDate', wbrDate)
            const localVarPath = `/report_executions/{reportId}/{wbrDate}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"wbrDate"}}`, encodeURIComponent(String(wbrDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific report
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all reports executions for given report_id
         * @param {string} reportId The id of the report to get executions for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportExecutions: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportExecutions', 'reportId', reportId)
            const localVarPath = `/report_executions/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific report item
         * @param {string} reportItemId The id of the report item to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportItem: async (reportItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('getReportItem', 'reportItemId', reportItemId)
            const localVarPath = `/report_items/{reportItemId}`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get status of query running for RunQuery feature from Redshift
         * @param {string} reportItemId The id of the report item to poll for result
         * @param {string} redshiftQueryId ID of query running in Redshift Data API
         * @param {string} itemType The item type for which this query is being running for
         * @param {string} queryFormat Format used in \&#39;query_multiple_result\&#39; item type to show result
         * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
         * @param {string} [reportItemName] Name of report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportItemQueryStatus: async (reportItemId: string, redshiftQueryId: string, itemType: string, queryFormat: string, reportId: string, reportItemName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('getReportItemQueryStatus', 'reportItemId', reportItemId)
            // verify required parameter 'redshiftQueryId' is not null or undefined
            assertParamExists('getReportItemQueryStatus', 'redshiftQueryId', redshiftQueryId)
            // verify required parameter 'itemType' is not null or undefined
            assertParamExists('getReportItemQueryStatus', 'itemType', itemType)
            // verify required parameter 'queryFormat' is not null or undefined
            assertParamExists('getReportItemQueryStatus', 'queryFormat', queryFormat)
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportItemQueryStatus', 'reportId', reportId)
            const localVarPath = `/report_items/{reportItemId}/poll`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reportItemName !== undefined) {
                localVarQueryParameter['reportItemName'] = reportItemName;
            }

            if (redshiftQueryId !== undefined) {
                localVarQueryParameter['redshiftQueryId'] = redshiftQueryId;
            }

            if (itemType !== undefined) {
                localVarQueryParameter['itemType'] = itemType;
            }

            if (queryFormat !== undefined) {
                localVarQueryParameter['queryFormat'] = queryFormat;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSchedule: async (reportScheduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('getReportSchedule', 'reportScheduleId', reportScheduleId)
            const localVarPath = `/report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SIM ticket metadata
         * @param {string} id The id of the report
         * @param {string} ticketId The id of the SIM ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimMetadata: async (id: string, ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSimMetadata', 'id', id)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getSimMetadata', 'ticketId', ticketId)
            const localVarPath = `/reports/{id}/sim/{ticketId}/metadata`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all report items
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportItems: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all report items in given report
         * @param {string} reportId The id of the report to retrieve for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportMetrics: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('listReportMetrics', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}/list`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all reports
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get past available wbr date ranges
         * @param {string} reportId The id of the report to retrieve min, max wbr date ranges.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pastAvailableReportDateRange: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('pastAvailableReportDateRange', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}/past_report_date_range`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary requests Approval record sync from Classic
         * @param {ApprovalSyncRequest} approvalSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApprovalSync: async (approvalSyncRequest: ApprovalSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'approvalSyncRequest' is not null or undefined
            assertParamExists('requestApprovalSync', 'approvalSyncRequest', approvalSyncRequest)
            const localVarPath = `/request_approval_sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary request report approval
         * @param {ReportApprovalRequest} reportApprovalRequest Request report approval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReportApproval: async (reportApprovalRequest: ReportApprovalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportApprovalRequest' is not null or undefined
            assertParamExists('requestReportApproval', 'reportApprovalRequest', reportApprovalRequest)
            const localVarPath = `/request_report_approval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportApprovalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve CF Signed Url
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCloudFrontSignedUrl: async (reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('retrieveCloudFrontSignedUrl', 'reportId', reportId)
            // verify required parameter 'retrieveCFUrlRequest' is not null or undefined
            assertParamExists('retrieveCloudFrontSignedUrl', 'retrieveCFUrlRequest', retrieveCFUrlRequest)
            const localVarPath = `/reports/{reportId}/cfsignedurl`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retrieveCFUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve Data Dump Result
         * @param {string} reportId The id of the report to retrieve data dump
         * @param {RetrieveDatadumpRequest} retrieveDatadumpRequest A report Id, name and a date that generated the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDatadumpResult: async (reportId: string, retrieveDatadumpRequest: RetrieveDatadumpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('retrieveDatadumpResult', 'reportId', reportId)
            // verify required parameter 'retrieveDatadumpRequest' is not null or undefined
            assertParamExists('retrieveDatadumpResult', 'retrieveDatadumpRequest', retrieveDatadumpRequest)
            const localVarPath = `/reports/{reportId}/data_dump_result`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retrieveDatadumpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve Report Result
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveReportResult: async (reportId: string, retrieveReportRequest: RetrieveReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('retrieveReportResult', 'reportId', reportId)
            // verify required parameter 'retrieveReportRequest' is not null or undefined
            assertParamExists('retrieveReportResult', 'retrieveReportRequest', retrieveReportRequest)
            const localVarPath = `/reports/{reportId}/result`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retrieveReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Runs query for Report Item on Redshift
         * @param {string} reportItemId The id of the report item to run query for
         * @param {ReportItem} reportItem A *Report Item* to run query for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runReportItemQuery: async (reportItemId: string, reportItem: ReportItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('runReportItemQuery', 'reportItemId', reportItemId)
            // verify required parameter 'reportItem' is not null or undefined
            assertParamExists('runReportItemQuery', 'reportItem', reportItem)
            const localVarPath = `/report_items/{reportItemId}/query`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Callback to continue report execution step function after verifying security workflows
         * @param {SubmitTaskTokenRequest} submitTaskTokenRequest A task token and the status of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTaskToken: async (submitTaskTokenRequest: SubmitTaskTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitTaskTokenRequest' is not null or undefined
            assertParamExists('submitTaskToken', 'submitTaskTokenRequest', submitTaskTokenRequest)
            const localVarPath = `/submit_task_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitTaskTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specific report
         * @param {string} reportId The id of the report to update
         * @param {Report} report A *Report* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport: async (reportId: string, report: Report, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReport', 'reportId', reportId)
            // verify required parameter 'report' is not null or undefined
            assertParamExists('updateReport', 'report', report)
            const localVarPath = `/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(report, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specific report item
         * @param {string} reportItemId The id of the report item to update
         * @param {ReportItem} reportItem A *Report Item* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportItem: async (reportItemId: string, reportItem: ReportItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportItemId' is not null or undefined
            assertParamExists('updateReportItem', 'reportItemId', reportItemId)
            // verify required parameter 'reportItem' is not null or undefined
            assertParamExists('updateReportItem', 'reportItem', reportItem)
            const localVarPath = `/report_items/{reportItemId}`
                .replace(`{${"reportItemId"}}`, encodeURIComponent(String(reportItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to update
         * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportSchedule: async (reportScheduleId: string, reportSchedule: ReportSchedule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportScheduleId' is not null or undefined
            assertParamExists('updateReportSchedule', 'reportScheduleId', reportScheduleId)
            // verify required parameter 'reportSchedule' is not null or undefined
            assertParamExists('updateReportSchedule', 'reportSchedule', reportSchedule)
            const localVarPath = `/report_schedule/{reportScheduleId}`
                .replace(`{${"reportScheduleId"}}`, encodeURIComponent(String(reportScheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportSchedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary check whether user alias in a group.
         * @param {string} userId The alias of user
         * @param {Array<string>} groupId The posix groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsMemberOfGroup: async (userId: string, groupId: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userIsMemberOfGroup', 'userId', userId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('userIsMemberOfGroup', 'groupId', groupId)
            const localVarPath = `/user_is_member_of_group/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (groupId) {
                localVarQueryParameter['group_id'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify if a user has access to given permission or not
         * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePermission: async (validatePermissionRequest: ValidatePermissionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validatePermissionRequest' is not null or undefined
            assertParamExists('validatePermission', 'validatePermissionRequest', validatePermissionRequest)
            const localVarPath = `/permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePermissionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify if user is authorized to access reports types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateReportTypeAuthorization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report_type_authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FondueApi - functional programming interface
 * @export
 */
export const FondueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FondueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancels a generate report request
         * @param {string} reportId The id of the report to cancel
         * @param {ReportExecution} reportExecution A Report Id and WBR date to cancel execution for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReportExecution(reportId: string, reportExecution: ReportExecution, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReportExecution(reportId, reportExecution, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancels running query
         * @param {string} reportItemId The id of the report item to run query for
         * @param {string} redshiftQueryId ID of query running in Redshift Data API
         * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelReportItemQuery(reportItemId: string, redshiftQueryId: string, reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelReportItemQuery(reportItemId, redshiftQueryId, reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a report
         * @param {Report} report A *Report* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(report: Report, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(report, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a report item
         * @param {ReportItem} reportItem A *Report Item* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportItem(reportItem: ReportItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportItem(reportItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Report Item by id
         * @param {string} reportItemId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportItem(reportItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportItem(reportItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disables specific Report Item
         * @param {string} reportItemId The id of the report item to disable
         * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableReportItem(reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableReportItem(reportItemId, disableReportItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableReportSchedule(reportScheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportItemId The id of the report item to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableReportItem(reportItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableReportItem(reportItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableReportSchedule(reportScheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportId The id of the report to schedule executions for
         * @param {ReportExecutionsRequest} reportExecutionsRequest List of WBR dates to request execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueueReportExecution(reportId: string, reportExecutionsRequest: ReportExecutionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueueReportExecution(reportId, reportExecutionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate IAM Policy DER MVP query, and create a related SIM ticket
         * @param {GenMVPRequest} genMVPRequest Parameters for generating MVP queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async genMVP(genMVPRequest: GenMVPRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenMVPResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.genMVP(genMVPRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportId The id of the report to generate
         * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReportSchedule(reportId: string, generateReportRequest: GenerateReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateReportSchedule(reportId, generateReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get execution metadata
         * @param {string} id The id of the report
         * @param {string} executionId The id of the execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExecutionMetadata(id: string, executionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExecutionMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExecutionMetadata(id, executionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all non finished queries of a given report and wbr date.
         * @param {string} reportId The id of the report to get executions for
         * @param {string} wbrDate The wbr date for which the non-finshed queries are needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNonFinishedQueries(reportId: string, wbrDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NonFinishedQueriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNonFinishedQueries(reportId, wbrDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific report
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all reports executions for given report_id
         * @param {string} reportId The id of the report to get executions for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportExecutions(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportExecutions(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific report item
         * @param {string} reportItemId The id of the report item to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportItem(reportItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportItem(reportItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get status of query running for RunQuery feature from Redshift
         * @param {string} reportItemId The id of the report item to poll for result
         * @param {string} redshiftQueryId ID of query running in Redshift Data API
         * @param {string} itemType The item type for which this query is being running for
         * @param {string} queryFormat Format used in \&#39;query_multiple_result\&#39; item type to show result
         * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
         * @param {string} [reportItemName] Name of report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportItemQueryStatus(reportItemId: string, redshiftQueryId: string, itemType: string, queryFormat: string, reportId: string, reportItemName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportItemQueryStatus(reportItemId, redshiftQueryId, itemType, queryFormat, reportId, reportItemName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportSchedule(reportScheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SIM ticket metadata
         * @param {string} id The id of the report
         * @param {string} ticketId The id of the SIM ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimMetadata(id: string, ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSimMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimMetadata(id, ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all report items
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportItems(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportItems(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all report items in given report
         * @param {string} reportId The id of the report to retrieve for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportMetrics(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportMetrics(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all reports
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Report>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get past available wbr date ranges
         * @param {string} reportId The id of the report to retrieve min, max wbr date ranges.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pastAvailableReportDateRange(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PastAvailableReportDateRangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pastAvailableReportDateRange(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary requests Approval record sync from Classic
         * @param {ApprovalSyncRequest} approvalSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestApprovalSync(approvalSyncRequest: ApprovalSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestApprovalSync(approvalSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary request report approval
         * @param {ReportApprovalRequest} reportApprovalRequest Request report approval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestReportApproval(reportApprovalRequest: ReportApprovalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportApprovalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestReportApproval(reportApprovalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve CF Signed Url
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveCloudFrontSignedUrl(reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveCFUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveCloudFrontSignedUrl(reportId, retrieveCFUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve Data Dump Result
         * @param {string} reportId The id of the report to retrieve data dump
         * @param {RetrieveDatadumpRequest} retrieveDatadumpRequest A report Id, name and a date that generated the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveDatadumpResult(reportId: string, retrieveDatadumpRequest: RetrieveDatadumpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveDatadumpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveDatadumpResult(reportId, retrieveDatadumpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve Report Result
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveReportResult(reportId: string, retrieveReportRequest: RetrieveReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RetrieveReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveReportResult(reportId, retrieveReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Runs query for Report Item on Redshift
         * @param {string} reportItemId The id of the report item to run query for
         * @param {ReportItem} reportItem A *Report Item* to run query for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runReportItemQuery(reportItemId: string, reportItem: ReportItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runReportItemQuery(reportItemId, reportItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Callback to continue report execution step function after verifying security workflows
         * @param {SubmitTaskTokenRequest} submitTaskTokenRequest A task token and the status of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTaskToken(submitTaskTokenRequest: SubmitTaskTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitTaskTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitTaskToken(submitTaskTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a specific report
         * @param {string} reportId The id of the report to update
         * @param {Report} report A *Report* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReport(reportId: string, report: Report, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReport(reportId, report, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a specific report item
         * @param {string} reportItemId The id of the report item to update
         * @param {ReportItem} reportItem A *Report Item* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportItem(reportItemId: string, reportItem: ReportItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportItem(reportItemId, reportItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to update
         * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReportSchedule(reportScheduleId: string, reportSchedule: ReportSchedule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportSchedule(reportScheduleId, reportSchedule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary check whether user alias in a group.
         * @param {string} userId The alias of user
         * @param {Array<string>} groupId The posix groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userIsMemberOfGroup(userId: string, groupId: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIsMemberOfGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userIsMemberOfGroup(userId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify if a user has access to given permission or not
         * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePermission(validatePermissionRequest: ValidatePermissionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidatePermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePermission(validatePermissionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify if user is authorized to access reports types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateReportTypeAuthorization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateReportTypeAuthorizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateReportTypeAuthorization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FondueApi - factory interface
 * @export
 */
export const FondueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FondueApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancels a generate report request
         * @param {string} reportId The id of the report to cancel
         * @param {ReportExecution} reportExecution A Report Id and WBR date to cancel execution for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReportExecution(reportId: string, reportExecution: ReportExecution, options?: any): AxiosPromise<GenerateReportResponse> {
            return localVarFp.cancelReportExecution(reportId, reportExecution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels running query
         * @param {string} reportItemId The id of the report item to run query for
         * @param {string} redshiftQueryId ID of query running in Redshift Data API
         * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelReportItemQuery(reportItemId: string, redshiftQueryId: string, reportId: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.cancelReportItemQuery(reportItemId, redshiftQueryId, reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a report
         * @param {Report} report A *Report* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(report: Report, options?: any): AxiosPromise<void> {
            return localVarFp.createReport(report, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a report item
         * @param {ReportItem} reportItem A *Report Item* to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportItem(reportItem: ReportItem, options?: any): AxiosPromise<void> {
            return localVarFp.createReportItem(reportItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Report Item by id
         * @param {string} reportItemId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportItem(reportItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportItem(reportItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disables specific Report Item
         * @param {string} reportItemId The id of the report item to disable
         * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportItem(reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.disableReportItem(reportItemId, disableReportItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to disable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableReportSchedule(reportScheduleId: string, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.disableReportSchedule(reportScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportItemId The id of the report item to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportItem(reportItemId: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.enableReportItem(reportItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to enable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableReportSchedule(reportScheduleId: string, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.enableReportSchedule(reportScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables specific Report Item
         * @param {string} reportId The id of the report to schedule executions for
         * @param {ReportExecutionsRequest} reportExecutionsRequest List of WBR dates to request execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueueReportExecution(reportId: string, reportExecutionsRequest: ReportExecutionsRequest, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.enqueueReportExecution(reportId, reportExecutionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate IAM Policy DER MVP query, and create a related SIM ticket
         * @param {GenMVPRequest} genMVPRequest Parameters for generating MVP queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genMVP(genMVPRequest: GenMVPRequest, options?: any): AxiosPromise<GenMVPResponse> {
            return localVarFp.genMVP(genMVPRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enables a specific report schedule
         * @param {string} reportId The id of the report to generate
         * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReportSchedule(reportId: string, generateReportRequest: GenerateReportRequest, options?: any): AxiosPromise<GenerateReportResponse> {
            return localVarFp.generateReportSchedule(reportId, generateReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get execution metadata
         * @param {string} id The id of the report
         * @param {string} executionId The id of the execution
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutionMetadata(id: string, executionId: string, options?: any): AxiosPromise<GetExecutionMetadataResponse> {
            return localVarFp.getExecutionMetadata(id, executionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all non finished queries of a given report and wbr date.
         * @param {string} reportId The id of the report to get executions for
         * @param {string} wbrDate The wbr date for which the non-finshed queries are needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNonFinishedQueries(reportId: string, wbrDate: string, options?: any): AxiosPromise<NonFinishedQueriesResponse> {
            return localVarFp.getNonFinishedQueries(reportId, wbrDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific report
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all reports executions for given report_id
         * @param {string} reportId The id of the report to get executions for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportExecutions(reportId: string, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.getReportExecutions(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific report item
         * @param {string} reportItemId The id of the report item to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportItem(reportItemId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReportItem(reportItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get status of query running for RunQuery feature from Redshift
         * @param {string} reportItemId The id of the report item to poll for result
         * @param {string} redshiftQueryId ID of query running in Redshift Data API
         * @param {string} itemType The item type for which this query is being running for
         * @param {string} queryFormat Format used in \&#39;query_multiple_result\&#39; item type to show result
         * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
         * @param {string} [reportItemName] Name of report item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportItemQueryStatus(reportItemId: string, redshiftQueryId: string, itemType: string, queryFormat: string, reportId: string, reportItemName?: string, options?: any): AxiosPromise<QueryResult> {
            return localVarFp.getReportItemQueryStatus(reportItemId, redshiftQueryId, itemType, queryFormat, reportId, reportItemName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportSchedule(reportScheduleId: string, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.getReportSchedule(reportScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SIM ticket metadata
         * @param {string} id The id of the report
         * @param {string} ticketId The id of the SIM ticket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimMetadata(id: string, ticketId: string, options?: any): AxiosPromise<GetSimMetadataResponse> {
            return localVarFp.getSimMetadata(id, ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all report items
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportItems(limit?: number, options?: any): AxiosPromise<Array<ReportItem>> {
            return localVarFp.listReportItems(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all report items in given report
         * @param {string} reportId The id of the report to retrieve for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportMetrics(reportId: string, options?: any): AxiosPromise<ReportItem> {
            return localVarFp.listReportMetrics(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all reports
         * @param {number} [limit] How many items to return at one time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(limit?: number, options?: any): AxiosPromise<Array<Report>> {
            return localVarFp.listReports(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get past available wbr date ranges
         * @param {string} reportId The id of the report to retrieve min, max wbr date ranges.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pastAvailableReportDateRange(reportId: string, options?: any): AxiosPromise<PastAvailableReportDateRangeResponse> {
            return localVarFp.pastAvailableReportDateRange(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary requests Approval record sync from Classic
         * @param {ApprovalSyncRequest} approvalSyncRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestApprovalSync(approvalSyncRequest: ApprovalSyncRequest, options?: any): AxiosPromise<object> {
            return localVarFp.requestApprovalSync(approvalSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary request report approval
         * @param {ReportApprovalRequest} reportApprovalRequest Request report approval
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReportApproval(reportApprovalRequest: ReportApprovalRequest, options?: any): AxiosPromise<ReportApprovalResponse> {
            return localVarFp.requestReportApproval(reportApprovalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve CF Signed Url
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveCloudFrontSignedUrl(reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options?: any): AxiosPromise<RetrieveCFUrlResponse> {
            return localVarFp.retrieveCloudFrontSignedUrl(reportId, retrieveCFUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve Data Dump Result
         * @param {string} reportId The id of the report to retrieve data dump
         * @param {RetrieveDatadumpRequest} retrieveDatadumpRequest A report Id, name and a date that generated the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDatadumpResult(reportId: string, retrieveDatadumpRequest: RetrieveDatadumpRequest, options?: any): AxiosPromise<RetrieveDatadumpResponse> {
            return localVarFp.retrieveDatadumpResult(reportId, retrieveDatadumpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve Report Result
         * @param {string} reportId The id of the report to retrieve for
         * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveReportResult(reportId: string, retrieveReportRequest: RetrieveReportRequest, options?: any): AxiosPromise<RetrieveReportResponse> {
            return localVarFp.retrieveReportResult(reportId, retrieveReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Runs query for Report Item on Redshift
         * @param {string} reportItemId The id of the report item to run query for
         * @param {ReportItem} reportItem A *Report Item* to run query for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runReportItemQuery(reportItemId: string, reportItem: ReportItem, options?: any): AxiosPromise<MessageResponse> {
            return localVarFp.runReportItemQuery(reportItemId, reportItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Callback to continue report execution step function after verifying security workflows
         * @param {SubmitTaskTokenRequest} submitTaskTokenRequest A task token and the status of the task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTaskToken(submitTaskTokenRequest: SubmitTaskTokenRequest, options?: any): AxiosPromise<SubmitTaskTokenResponse> {
            return localVarFp.submitTaskToken(submitTaskTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a specific report
         * @param {string} reportId The id of the report to update
         * @param {Report} report A *Report* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReport(reportId: string, report: Report, options?: any): AxiosPromise<Report> {
            return localVarFp.updateReport(reportId, report, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a specific report item
         * @param {string} reportItemId The id of the report item to update
         * @param {ReportItem} reportItem A *Report Item* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportItem(reportItemId: string, reportItem: ReportItem, options?: any): AxiosPromise<ReportItem> {
            return localVarFp.updateReportItem(reportItemId, reportItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a specific report schedule
         * @param {string} reportScheduleId The id of the report schedule to update
         * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReportSchedule(reportScheduleId: string, reportSchedule: ReportSchedule, options?: any): AxiosPromise<ReportSchedule> {
            return localVarFp.updateReportSchedule(reportScheduleId, reportSchedule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary check whether user alias in a group.
         * @param {string} userId The alias of user
         * @param {Array<string>} groupId The posix groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userIsMemberOfGroup(userId: string, groupId: Array<string>, options?: any): AxiosPromise<UserIsMemberOfGroupResponse> {
            return localVarFp.userIsMemberOfGroup(userId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify if a user has access to given permission or not
         * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePermission(validatePermissionRequest: ValidatePermissionRequest, options?: any): AxiosPromise<ValidatePermissionResponse> {
            return localVarFp.validatePermission(validatePermissionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify if user is authorized to access reports types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateReportTypeAuthorization(options?: any): AxiosPromise<ValidateReportTypeAuthorizationResponse> {
            return localVarFp.validateReportTypeAuthorization(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FondueApi - object-oriented interface
 * @export
 * @class FondueApi
 * @extends {BaseAPI}
 */
export class FondueApi extends BaseAPI {
    /**
     * 
     * @summary Cancels a generate report request
     * @param {string} reportId The id of the report to cancel
     * @param {ReportExecution} reportExecution A Report Id and WBR date to cancel execution for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public cancelReportExecution(reportId: string, reportExecution: ReportExecution, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).cancelReportExecution(reportId, reportExecution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels running query
     * @param {string} reportItemId The id of the report item to run query for
     * @param {string} redshiftQueryId ID of query running in Redshift Data API
     * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public cancelReportItemQuery(reportItemId: string, redshiftQueryId: string, reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).cancelReportItemQuery(reportItemId, redshiftQueryId, reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a report
     * @param {Report} report A *Report* to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public createReport(report: Report, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).createReport(report, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a report item
     * @param {ReportItem} reportItem A *Report Item* to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public createReportItem(reportItem: ReportItem, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).createReportItem(reportItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Report by id
     * @param {string} reportId The id of the report to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public deleteReport(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).deleteReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Report Item by id
     * @param {string} reportItemId The id of the report to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public deleteReportItem(reportItemId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).deleteReportItem(reportItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disables specific Report Item
     * @param {string} reportItemId The id of the report item to disable
     * @param {DisableReportItemRequest} disableReportItemRequest Disable report item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public disableReportItem(reportItemId: string, disableReportItemRequest: DisableReportItemRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).disableReportItem(reportItemId, disableReportItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disables a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to disable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public disableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).disableReportSchedule(reportScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables specific Report Item
     * @param {string} reportItemId The id of the report item to enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public enableReportItem(reportItemId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).enableReportItem(reportItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to enable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public enableReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).enableReportSchedule(reportScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables specific Report Item
     * @param {string} reportId The id of the report to schedule executions for
     * @param {ReportExecutionsRequest} reportExecutionsRequest List of WBR dates to request execution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public enqueueReportExecution(reportId: string, reportExecutionsRequest: ReportExecutionsRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).enqueueReportExecution(reportId, reportExecutionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate IAM Policy DER MVP query, and create a related SIM ticket
     * @param {GenMVPRequest} genMVPRequest Parameters for generating MVP queries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public genMVP(genMVPRequest: GenMVPRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).genMVP(genMVPRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enables a specific report schedule
     * @param {string} reportId The id of the report to generate
     * @param {GenerateReportRequest} generateReportRequest A Report Id and WBR date to generate the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public generateReportSchedule(reportId: string, generateReportRequest: GenerateReportRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).generateReportSchedule(reportId, generateReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get execution metadata
     * @param {string} id The id of the report
     * @param {string} executionId The id of the execution
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getExecutionMetadata(id: string, executionId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getExecutionMetadata(id, executionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all non finished queries of a given report and wbr date.
     * @param {string} reportId The id of the report to get executions for
     * @param {string} wbrDate The wbr date for which the non-finshed queries are needed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getNonFinishedQueries(reportId: string, wbrDate: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getNonFinishedQueries(reportId, wbrDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific report
     * @param {string} reportId The id of the report to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReport(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all reports executions for given report_id
     * @param {string} reportId The id of the report to get executions for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReportExecutions(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReportExecutions(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific report item
     * @param {string} reportItemId The id of the report item to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReportItem(reportItemId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReportItem(reportItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get status of query running for RunQuery feature from Redshift
     * @param {string} reportItemId The id of the report item to poll for result
     * @param {string} redshiftQueryId ID of query running in Redshift Data API
     * @param {string} itemType The item type for which this query is being running for
     * @param {string} queryFormat Format used in \&#39;query_multiple_result\&#39; item type to show result
     * @param {string} reportId Report ID required to initialize RedshiftDataAPI class object
     * @param {string} [reportItemName] Name of report item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReportItemQueryStatus(reportItemId: string, redshiftQueryId: string, itemType: string, queryFormat: string, reportId: string, reportItemName?: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReportItemQueryStatus(reportItemId, redshiftQueryId, itemType, queryFormat, reportId, reportItemName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getReportSchedule(reportScheduleId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getReportSchedule(reportScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SIM ticket metadata
     * @param {string} id The id of the report
     * @param {string} ticketId The id of the SIM ticket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public getSimMetadata(id: string, ticketId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).getSimMetadata(id, ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all report items
     * @param {number} [limit] How many items to return at one time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public listReportItems(limit?: number, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).listReportItems(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all report items in given report
     * @param {string} reportId The id of the report to retrieve for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public listReportMetrics(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).listReportMetrics(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all reports
     * @param {number} [limit] How many items to return at one time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public listReports(limit?: number, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).listReports(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get past available wbr date ranges
     * @param {string} reportId The id of the report to retrieve min, max wbr date ranges.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public pastAvailableReportDateRange(reportId: string, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).pastAvailableReportDateRange(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary requests Approval record sync from Classic
     * @param {ApprovalSyncRequest} approvalSyncRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public requestApprovalSync(approvalSyncRequest: ApprovalSyncRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).requestApprovalSync(approvalSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary request report approval
     * @param {ReportApprovalRequest} reportApprovalRequest Request report approval
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public requestReportApproval(reportApprovalRequest: ReportApprovalRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).requestReportApproval(reportApprovalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve CF Signed Url
     * @param {string} reportId The id of the report to retrieve for
     * @param {RetrieveCFUrlRequest} retrieveCFUrlRequest A Report Id to check user permissions on
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public retrieveCloudFrontSignedUrl(reportId: string, retrieveCFUrlRequest: RetrieveCFUrlRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).retrieveCloudFrontSignedUrl(reportId, retrieveCFUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve Data Dump Result
     * @param {string} reportId The id of the report to retrieve data dump
     * @param {RetrieveDatadumpRequest} retrieveDatadumpRequest A report Id, name and a date that generated the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public retrieveDatadumpResult(reportId: string, retrieveDatadumpRequest: RetrieveDatadumpRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).retrieveDatadumpResult(reportId, retrieveDatadumpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve Report Result
     * @param {string} reportId The id of the report to retrieve for
     * @param {RetrieveReportRequest} retrieveReportRequest A Report Id and WBR date to retrieve result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public retrieveReportResult(reportId: string, retrieveReportRequest: RetrieveReportRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).retrieveReportResult(reportId, retrieveReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Runs query for Report Item on Redshift
     * @param {string} reportItemId The id of the report item to run query for
     * @param {ReportItem} reportItem A *Report Item* to run query for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public runReportItemQuery(reportItemId: string, reportItem: ReportItem, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).runReportItemQuery(reportItemId, reportItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Callback to continue report execution step function after verifying security workflows
     * @param {SubmitTaskTokenRequest} submitTaskTokenRequest A task token and the status of the task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public submitTaskToken(submitTaskTokenRequest: SubmitTaskTokenRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).submitTaskToken(submitTaskTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a specific report
     * @param {string} reportId The id of the report to update
     * @param {Report} report A *Report* to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public updateReport(reportId: string, report: Report, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).updateReport(reportId, report, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a specific report item
     * @param {string} reportItemId The id of the report item to update
     * @param {ReportItem} reportItem A *Report Item* to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public updateReportItem(reportItemId: string, reportItem: ReportItem, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).updateReportItem(reportItemId, reportItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a specific report schedule
     * @param {string} reportScheduleId The id of the report schedule to update
     * @param {ReportSchedule} reportSchedule A *Report Schedule* to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public updateReportSchedule(reportScheduleId: string, reportSchedule: ReportSchedule, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).updateReportSchedule(reportScheduleId, reportSchedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary check whether user alias in a group.
     * @param {string} userId The alias of user
     * @param {Array<string>} groupId The posix groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public userIsMemberOfGroup(userId: string, groupId: Array<string>, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).userIsMemberOfGroup(userId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify if a user has access to given permission or not
     * @param {ValidatePermissionRequest} validatePermissionRequest User details and type of permission needed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public validatePermission(validatePermissionRequest: ValidatePermissionRequest, options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).validatePermission(validatePermissionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify if user is authorized to access reports types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FondueApi
     */
    public validateReportTypeAuthorization(options?: AxiosRequestConfig) {
        return FondueApiFp(this.configuration).validateReportTypeAuthorization(options).then((request) => request(this.axios, this.basePath));
    }
}


