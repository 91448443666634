import Button from '@amzn/awsui-components-react/polaris/button';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import Container from '@amzn/awsui-components-react/polaris/container';
import ExpandableSection from '@amzn/awsui-components-react/polaris/expandable-section';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import { PRICE_CLASS_OPTIONS, SSL_CERTIFICATE_OPTIONS, SUPPORTED_HTTP_VERSIONS_OPTIONS } from './form-config';
import { useCheckbox, useInput, useRadio } from '../hooks';

// Footer content (Additional configuration section) for 'Distribution settings' form section
function DistributionsFooter() {
  const httpVersions = useRadio('http2');
  const rootObject = useInput('');
  const loggingEnabled = useCheckbox();
  const ipv6Enabled = useCheckbox();
  const comment = useInput();
  return (
    <ExpandableSection header="Additional configuration" variant="footer">
      <SpaceBetween size="l">
        <FormField
          label="Supported HTTP versions"
          description="Choose the version of the HTTP protocol that you want CloudFront to accept for viewer requests."
          stretch={true}
        >
          <RadioGroup {...httpVersions} items={SUPPORTED_HTTP_VERSIONS_OPTIONS} />
        </FormField>
        <FormField
          label="Root object"
          description="Type the name of the object that you want CloudFront to return when a viewer request points to your root URL."
        >
          <Input {...rootObject} />
        </FormField>
        <FormField label="Logging">
          <Checkbox {...loggingEnabled}>Enable logging</Checkbox>
        </FormField>
        <FormField label="IPv6">
          <Checkbox {...ipv6Enabled}>Enabled</Checkbox>
        </FormField>
        <FormField label="Comment">
          <Textarea {...comment} />
        </FormField>
      </SpaceBetween>
    </ExpandableSection>
  );
}

interface DistributionsPanelProps {
  onTopicSelect(topicName: string): void;
}

export default function DistributionsPanel({ onTopicSelect }: DistributionsPanelProps) {
  const priceClass = useRadio('0');
  const cnames = useInput('');
  const certificate = useRadio('default');
  return (
    <Container header={<Header>Distribution settings</Header>} footer={<DistributionsFooter />}>
      <SpaceBetween size="l">
        <FormField
          label="Price class"
          info={
            <Link variant="info" onFollow={() => onTopicSelect('priceClass')}>
              Info
            </Link>
          }
          stretch={true}
        >
          <RadioGroup {...priceClass} items={PRICE_CLASS_OPTIONS} />
        </FormField>
        <FormField
          label={
            <>
              Alternative domain names (CNAMEs)
              <i> - optional</i>
            </>
          }
          info={
            <Link variant="info" onFollow={() => onTopicSelect('alternativeDomains')}>
              Info
            </Link>
          }
          description="You must list any custom domain names that you use in addition to the CloudFront domain name for the URLs for your files."
          constraintText="Specify up to 100 CNAMEs separated with commas or put each on a new line."
          stretch={true}
        >
          <Textarea {...cnames} placeholder={'www.example1.com\nwww.example2.com'} />
        </FormField>
        <FormField
          label="SSL/TLS certificate"
          info={
            <Link variant="info" onFollow={() => onTopicSelect('sslCertificate')}>
              Info
            </Link>
          }
          stretch={true}
        >
          <RadioGroup {...certificate} items={SSL_CERTIFICATE_OPTIONS} />
        </FormField>
        <Button>Request or import a certificate with AWS Certificate Manager (ACM)</Button>
      </SpaceBetween>
    </Container>
  );
}
