import Form from '@amzn/awsui-components-react/polaris/form';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { useHistory } from 'react-router-dom';
import { ContentOrigin } from '../../interfaces';
import ContentDeliveryPanel from './ContentDeliveryPanel';
import DistributionsPanel from './DistributionsPanel';
import OriginsPanel from './OriginsPanel';
import CacheBehaviorsPanel from './CacheBehaviorsPanel';

interface FormContentProps {
  contentOrigins: ContentOrigin[];
  onTopicSelect: (topic: string) => void;
}

export default function FormContent({ contentOrigins, onTopicSelect }: FormContentProps) {
  const history = useHistory();
  return (
    <Form
      header={<Header variant="h1">Create CloudFront distribution</Header>}
      actions={
        // located at the bottom of the form
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link">Cancel</Button>
          <Button href="#/table" variant="primary" onFollow={() => history.push('/table')}>
            Create distribution
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween size="l">
        <ContentDeliveryPanel onTopicSelect={onTopicSelect} />
        <DistributionsPanel onTopicSelect={onTopicSelect} />
        <OriginsPanel contentOrigins={contentOrigins} onTopicSelect={onTopicSelect} />
        <CacheBehaviorsPanel />
      </SpaceBetween>
    </Form>
  );
}
