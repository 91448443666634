import { getShoehornUserAuthPromise } from '@amzn/shoehorn-service-website-helper';

export async function promptUserSimAnd2PR(authUrl: string) {
    try {
        var domain = window.location.hostname.split('.')[0];
        // TODO: may need to override endpoint url depending on domain for other partitions (e.g. *.cn)
        if (domain === 'prod') {
            await getShoehornUserAuthPromise(authUrl, {preventAutoClose: true, endpoint: 'https://auth.prod.web.shoehorn.security.aws.dev'});
        } else {
            await getShoehornUserAuthPromise(authUrl, {preventAutoClose: true, endpoint: 'https://auth.gamma.web.shoehorn.security.aws.dev'});
        }
        // TODO: investigate with shoehorn team when endpoint isn't specified
        // website modal doesn't automatically close and throws ContingentAuthorizationWorkflowCancelled error
        // await getShoehornUserAuthPromise(authUrl, {preventAutoClose: true});
    } catch(error) {
        throw error;
    }
}
