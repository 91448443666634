export enum States {
    loading,
    success,
    error,
    submitting,
    invalid,
    forbidden,
    failedCreate,
    deletingReport,
    failedDeleteReport,
    deleteReportSuccess,
    savingReport,
    saveReportSuccess,
    updatingReportItem,
    updateReportItemSuccess,
    updateReportItemFailed,
    getReportForbidden,
    deleteReportForbidden,
    updateReportForbidden,
    getReportItemForbidden,
    updateReportItemForbidden,
    updateReportItemConsoleDatashareDeny
}

export enum FlashbarType {
    info = "info",
    error = "error",
    success = "success",
    warning = "warning",
}

