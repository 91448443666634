import React, {useCallback, useEffect, useRef, useState} from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import { Report, ReportItem, FondueApi } from '../../fondue-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {Box, ButtonDropdown, ColumnLayout, Container, ExpandableSection, Grid} from "@amzn/awsui-components-react/polaris";
import Form from "@amzn/awsui-components-react/polaris/form";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Textarea from '@amzn/awsui-components-react/polaris/textarea';
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from '@amzn/awsui-components-react/polaris/select';
import { useSelect, useMultiselect, useInput } from '../hooks';
import { States } from "../../common/States";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import { GetReportItemTypes, IsDerReport } from '../../common/ReportTypes';
import DerReportItemForm from './custom/DerReportItemForm';
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Table from "@amzn/awsui-components-react/polaris/table";
import {DateUtil} from "../../util/DateUtil";
import {Util} from "../../util/Util";
import RunQueryComponent from "./RunQueryComponent";
import * as constants from '../../common/constants';
import AceEditor from 'react-ace';
import 'brace/theme/sqlserver';
import 'brace/theme/tomorrow_night_bright';
import 'brace/mode/sql';
import {useData} from '../DataContext';
export interface EditMetricFormProps {
    setState: (state: States) => void;
    updatingReportItem: string;
    setUpdatingReportItem: (x: any) => void;
    report: Report;
    reportItem: ReportItem | null;
    setReportItem: (ReportItem) => void;
    setEditSelectReportItem: (x: string) => void;
    setAddDisabled: (x: boolean) => void;
    setEditDisabled: (x: boolean) => void;
    setAddEditInfoDisabled: (x: boolean) => void;
    refreshTable: boolean;
    setRefreshTable: (refresh: boolean) => void;
    setSplitPanelTitle,
    allReportItems: ReportItem[]
}

export default function({setState, updatingReportItem, setUpdatingReportItem, report, reportItem, setReportItem, setEditSelectReportItem, setAddDisabled, setEditDisabled, setAddEditInfoDisabled, refreshTable, setRefreshTable, setSplitPanelTitle, allReportItems}: EditMetricFormProps) {
  Object.freeze(Object.prototype);
  const [itemID, setItemID] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemRowNum, setItemRowNum] = useState( (reportItem && reportItem!.metric_number) ? (reportItem.metric_number.toString()) : "");
  const [validItemRowNum, setValidItemRowNum] = useState(true);
  const [itemQuery, setItemQuery] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemFormat, setItemFormat] = useState("");
  const [selectionOption, setSelectionOption] = useState({ "value": reportItem ? reportItem!.type : ""})
  const [disableQuery, setDisableQuery] = useState(false);
  const [disableFormat, setDisableFormat] = useState(false);
  const [formButtonsDisabled, setFormButtonsDisabled] = useState(false);
  const [disableAdditionalComponents, setDisableAdditionalComponents] = useState(false);
  const [additionalMetricItems, setAdditionalMetricItems] = useState<any>([])
  const itemTypeOptions = GetReportItemTypes(report);
  const [saveReportItemLoading, setSaveReportItemLoading] = useState(false);
  const [submitReportItemLoading, setSubmitReportItemLoading] = useState(false);
  const {darkMode} = useData();
  // Total number of Report Items in Items Table
  const totalReportItems = allReportItems.length;

    /**Returns if should allow empty sql query */
  function AllowEmptySqlQuery(): boolean {
    return IsDerReport(report); // Allow for IAM DER
  }


    async function saveReportItem(reportItemConfig?: {}) {
    setSaveReportItemLoading(true);
    setSubmitReportItemLoading(true);
    await getMidwayJwtToken();
    const itemType = selectionOption['value'];
    // If Item is of type header and name not provided, setState to invalid input
    if(itemType === 'header' && itemName.trim() === ""){
        setState(States.invalid)
        setSaveReportItemLoading(false);
        setSubmitReportItemLoading(false);
        return;
    }

    // Check if row item number provided is in valid range
    if(!validateRowItemNumber(itemRowNum)){
        setState(States.invalid)
        setSaveReportItemLoading(false);
        setSubmitReportItemLoading(false);
        setValidItemRowNum(false);
        return;
    }

    // If Item is of type query and query or name is not provided, setState to invalid input
    if(!AllowEmptySqlQuery() && itemType !== 'header' && (itemQuery.trim() === "" || itemName.trim() ==="")){
        setState(States.invalid)
        setSaveReportItemLoading(false);
        setSubmitReportItemLoading(false);
        return;
    }

    // If Item is of type query_multiple_result and no format is provided, setState to invalid input
    if(itemType === 'query_multiple_result' && itemFormat.trim() === ""){
        setSaveReportItemLoading(false);
        setSubmitReportItemLoading(false);
        setState(States.invalid)
        return;
    }

    const FondueApi = FondueApiFactory();
    if (reportItem){
        // Update reportItem object
        reportItem.id = itemID;
        reportItem.description = itemDescription;
        reportItem.name = itemName;
        reportItem.type = selectionOption['value'];
        reportItem.query = itemQuery;
        reportItem.format = itemFormat;
        reportItem.metric_number = Number(itemRowNum);
        var options = {}
        var minOptionsCount = 0
        additionalMetricItems.map(item => {
            if(item.enabled){
                options[item.value] = 'true'
                minOptionsCount += 1
            }
            else options[item.value] = 'false'
        })
        if(minOptionsCount == 0 || Object.keys(options).length === 0){
            setSaveReportItemLoading(false);
            setSubmitReportItemLoading(false);
            setState(States.invalid)
            return 
        }
        reportItem.report_item_config =  {
            ...reportItemConfig,
            ...options
        }
        // Add query and/or format for query item
        if(itemType !== 'header'){
            reportItem['query'] = itemQuery;

            // Add format for multiple result type
            if(itemType === 'query_multiple_result'){
                reportItem['format'] = itemFormat;
            }
        }

        setState(States.updatingReportItem);
        setFormButtonsDisabled(true);

        // Setting validItemRowNum to true in case invalid input was provided first
        setValidItemRowNum(true);

        // Calling updateReportItem
        await FondueApi.updateReportItem(reportItem.id, reportItem)
            .then((response) => {
                setUpdatingReportItem('WAIT')
                setRefreshTable(true);
                setAddDisabled(true);
            })
            .catch((e)=> {
                if (e.response.status === 404){
                    setFormButtonsDisabled(false);
                    setState(States.updateReportItemConsoleDatashareDeny);
                }
                else{
                    setFormButtonsDisabled(false);
                    setRefreshTable(true);

                    // Closing Modal after submission of update report item
                    setAddEditInfoDisabled(false);
                    setAddDisabled(true);
                }
        });

    }
    setSaveReportItemLoading(false);
    setSubmitReportItemLoading(false);
  }
  useEffect (() => {
    if(refreshTable == false && updatingReportItem=='DONE'){
        setEditSelectReportItem(reportItem ? reportItem.id : '')
        setFormButtonsDisabled(false);
        setState(States.updateReportItemSuccess);
        setUpdatingReportItem('INIT')
    }
  }, [updatingReportItem, refreshTable, setRefreshTable])
  function updateFormOnItemTypeSelect(itemType){
    setSelectionOption(itemTypeOptions[itemType]);
    if(itemType === 'header') {
      setDisableQuery(true);
      setDisableFormat(true);
      setDisableAdditionalComponents(true);
    }
    // Disable format field for query_single_result
    else {
      setDisableQuery(false);
      if(itemType === 'query_multiple_result'){
          setDisableFormat(false);
          setDisableAdditionalComponents(true);
      }
      else{
          setDisableFormat(true);
          setDisableAdditionalComponents(false);
      }
    }
  }

  function cancelAddItem() {
    // Clear Form
    setItemName('');
    setItemRowNum('');
    setItemID('');
    setItemQuery('');
    updateFormOnItemTypeSelect('');
    setItemDescription('');
    setAdditionalMetricItems(constants.ADDITIONAL_METRIC_CALC_OPTIONS);
    // Show info
    setAddEditInfoDisabled(false);
    setAddDisabled(true);
    setEditDisabled(true);
  }

    function getReportItem(){
        const reportItem: ReportItem = {
            id: itemID,
            name: itemName,
            type: selectionOption.value,
            report_id: report.id,
            bindle: report.bindle,
            report_item_config: additionalMetricItems,
            description: itemDescription,
            query: itemQuery,
            format: itemFormat,
            metric_number: Number(itemRowNum)
        };

        return reportItem;
    }

  function resetFields() {
    if (reportItem){
        setItemName(reportItem.name);
        setItemRowNum((reportItem.metric_number) ? (reportItem.metric_number.toString()) : (""));
        setValidItemRowNum(true);
        setItemID(reportItem.id);
        setItemQuery((reportItem.query) ? (reportItem.query) : (''));
        const currentItemType = itemTypeOptions[(reportItem) ? (reportItem.type) : ('')];
        if (currentItemType){
            updateFormOnItemTypeSelect(currentItemType.value);
        }
        setItemDescription(reportItem.description ? (reportItem.description) : (''));
        setAdditionalMetricItems(constants.ADDITIONAL_METRIC_CALC_OPTIONS);
    }
  }
  function handleAdditionalMetricCalcSelection(e, item){
    var tempCalc = additionalMetricItems.map(obj => {
        if(obj.value == item.value){
            obj.enabled = e.detail.checked
        }
        return obj
    })
    setAdditionalMetricItems(tempCalc)
  }
  function fillAdditionalMetricCalcOptions(report_item_config, obj){
    if (report_item_config){
        if(obj.value == 'qr' && obj.value in report_item_config && report_item_config[obj.value]=='false'){
            return false
        }
        else if(obj.value != 'qr' && (obj.value in report_item_config && report_item_config[obj.value]=='false' || !(obj.value in report_item_config))){
            return false
        }
        return true
    }
    else if(!report_item_config && obj.value == 'qr'){
        return true
    }
    return false
  }

    // Function to validate RowNumber string is Numeric and within valid bounds
    function validateRowItemNumber(rowNumber: string){
        if(rowNumber && !isNaN(Number(rowNumber))){
            if(Number(rowNumber) <= totalReportItems && Number(rowNumber) > 0){
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    useEffect(() => {
    setItemName((reportItem) ? (reportItem.name) : (''));
    setSplitPanelTitle((reportItem) ? (reportItem.name) : (constants.EDIT_REPORT_ITEM_LABEL))
    setItemID((reportItem) ? (reportItem.id) : (''));
    setItemRowNum((reportItem && reportItem.metric_number) ? (reportItem.metric_number.toString()) : (""));
    setItemQuery((reportItem && reportItem.query) ? (reportItem.query) : (''));
    const currentItemType = itemTypeOptions[(reportItem) ? (reportItem.type) : ('')];
    if (currentItemType){
      updateFormOnItemTypeSelect(currentItemType.value);
      if(currentItemType.value == 'query_multiple_result'){
        setItemFormat((reportItem && reportItem.format) ? (reportItem.format) : (''));
      }
    }
    setItemDescription((reportItem && reportItem.description) ? (reportItem.description) : (''));
    const report_item_config = reportItem?.report_item_config
    var tempCalc = constants.ADDITIONAL_METRIC_CALC_OPTIONS.map(obj => ({
        ...obj,
        enabled: fillAdditionalMetricCalcOptions(report_item_config, obj)
    }))
    setAdditionalMetricItems(tempCalc)
  }, [reportItem, setReportItem]);

  return (
            IsDerReport(report)
                ? <DerReportItemForm clearFields={cancelAddItem} createReportItem={saveReportItem} disabled={formButtonsDisabled} isEditing={true} resetFields={resetFields}
                                    itemDescriptionState={[itemDescription, setItemDescription]}
                                    itemNameState={[itemName, setItemName]}
                                    itemQueryState={[itemQuery, setItemQuery]}
                                    reportItem={reportItem} 
                                    metricTypeState={[selectionOption, (event) => {}]}
                                    itemTypeOptions={itemTypeOptions}
                                    submitReportItemLoading={submitReportItemLoading}
                                    />
                :<Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button id="cancel" variant="normal" onClick={cancelAddItem} disabled={formButtonsDisabled}>
                                {constants.CANCEL}
                            </Button>
                            <Button id="reset" variant="normal" onClick={resetFields} disabled={formButtonsDisabled}>
                                {constants.RESET}
                            </Button>
                            <Button id="submit" variant="primary" loading={saveReportItemLoading} onClick={() => saveReportItem()} disabled={formButtonsDisabled}>
                                {constants.SAVE}
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <ColumnLayout columns={2}>
                    <FormField
                        id='row_num'
                        label={constants.EDIT_REPORT_ITEM_METRIC_NUMBER_LABEL} stretch ={true}
                        constraintText={`Choose a valid row between 1 and ${allReportItems.length}.`}
                    >
                        <Input
                            inputMode="numeric"
                            type="number"
                            value = {itemRowNum.toString()}
                            onChange={(event) =>
                                setItemRowNum(event.detail.value)
                            }
                            invalid={!validItemRowNum}
                        />
                    </FormField>
                    <FormField
                        id='metrictype'
                        label={constants.EDIT_REPORT_ITEM_TYPE_LABEL} stretch>
                        <Select selectedOption={selectionOption}
                                placeholder="Select metric type"
                                options={Object.keys(itemTypeOptions).map((itemType) => { return itemTypeOptions[itemType] })}
                                onChange={event => {
                                    updateFormOnItemTypeSelect(event.detail.selectedOption.value);
                                }}/>
                    </FormField>
                </ColumnLayout>
                <SpaceBetween size="m">    
                    <FormField
                        id='editname'
                        label={constants.EDIT_REPORT_ITEM_NAME_LABEL} stretch>
                        <Input
                            value = {itemName}
                            onChange={(event) =>
                                setItemName(event.detail.value)
                            }
                        />
                    </FormField>
                    <FormField
                        id='description'
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_DESCRIPTION_LABEL} <i> - optional </i>{" "}
                            </span>
                        }
                        stretch
                    >
                        <Textarea
                            value={itemDescription}
                            rows={3}
                            onChange={({detail}) =>
                                setItemDescription(detail.value)}
                        />
                    </FormField>
                    {
                        !disableQuery && <FormField
                        id='query'
                        label={
                            <span>
                                {constants.EDIT_REPORT_ITEM_SQL_QUERY_LABEL}  <i> - required for query type </i>{" "}
                            </span>
                        }
                        stretch
                        description= {constants.EDIT_REPORT_ITEM_SQL_QUERY_DESCRIPTION} 
                    >
                        <AceEditor mode="sql" theme={darkMode ? 'tomorrow_night_bright': 'sqlserver'} width='100%' height='200px'
                                        style={{border: "1px solid #ccc"}}
                                        readOnly={disableQuery}
                                        onChange={(e) => setItemQuery(e)}
                                        value={itemQuery}
                                        fontSize={13}
                                        onLoad={editorInstance => {
                                            editorInstance.container.style.resize = "vertical";
                                            // mouseup = css resize end
                                            document.addEventListener("mouseup", e => (
                                            editorInstance.resize()
                                            ));
                                        }}
                                        setOptions={{
                                            showPrintMargin: false,
                                            highlightActiveLine: true,
                                            enableSnippets: true,
                                            wrap: true,
                                            indentedSoftWrap: false,
                                            displayIndentGuides: true,
                                            dragEnabled: true
                                        }}
                            /> 
                    </FormField>
                    }
                    {
                        !disableFormat &&
                        <FormField
                            id='format'
                            stretch
                            label={
                                <span>
                                    {constants.EDIT_REPORT_ITEM_QUERY_FORMAT} <i> - required for query_multiple_result </i>{" "}
                                </span>
                            }
                            description= {constants.EDIT_REPORT_ITEM_QUERY_FORMAT_DESCRIPTION}
                        >
                            <Input
                                disabled={disableFormat}
                                value = {itemFormat}
                                onChange={event =>
                                    setItemFormat(event.detail.value)}
                                placeholder={"{account} - {count}"}
                            />
                        </FormField>
                    }
                    {   !disableAdditionalComponents && additionalMetricItems.length > 0 &&
                        <ExpandableSection
                            id = 'additionalmetriccomponents'
                            headerText = {
                                <span>
                                    {constants.EDIT_REPORT_ITEM_ADDITIONAL_ITEMS_CALCULATION_LABEL} <i> - 1 required</i>
                                </span>
                            }
                        >
                            {
                                additionalMetricItems.map(item => {
                                    return <Checkbox key={item.value}
                                        onChange={ (e) => {
                                            handleAdditionalMetricCalcSelection(e, item)
                                        } }
                                        checked={item.enabled}
                                        description={item.description}
                                        >
                                        {item.label}
                                    </Checkbox>
                                })
                            }
                        </ExpandableSection>
                    }
                    {
                        selectionOption.value !== 'header' && <RunQueryComponent reportItem={getReportItem()}/>
                    }              
                </SpaceBetween>
                </Form>
  );
};
