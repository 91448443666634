import { useState } from 'react';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import HomepageContent from './HomepageContent';
import { ServiceNavigation } from '../navigation';
import { appLayoutNavigationLabels } from '../../common/labels';
import './styles.scss';
import { useDocumentTitle } from '../hooks';
import * as constants from '../../common/constants';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
export default function ServiceHomepage() {
  const [navigationOpen, setNavigationOpen] = useState(true);
  useDocumentTitle(constants.DEFAULT_TAB_TITLE)
  
  return (
      <AppLayout
      headerSelector='#topNavigation'
      navigation={<ServiceNavigation />}
      content={<HomepageContent />}
      contentType="default"
      disableContentPaddings={true}
      navigationOpen={navigationOpen}
      onNavigationChange={event => setNavigationOpen(event.detail.open)}
      toolsHide={true}
      ariaLabels={appLayoutNavigationLabels}
    />
    
  );
}
