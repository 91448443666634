import { useState } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import Select from '@amzn/awsui-components-react/polaris/select';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import Input from '@amzn/awsui-components-react/polaris/input';
import AttributeEditor from '@amzn/awsui-components-react/polaris/attribute-editor';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { ContentOrigin } from '../../interfaces';
import { useSelect, useMultiselect, useInput } from '../hooks';

interface HeaderItem {
  name: string;
  value: string;
}

interface OriginsPanelProps {
  contentOrigins: ContentOrigin[];
  onTopicSelect: (topic: string) => void;
}

function updateHeader(items: Array<HeaderItem>, itemIndex: number, updater: (header: HeaderItem) => HeaderItem) {
  return items.map((item, index) => (index === itemIndex ? updater(item) : item));
}

export default function OriginsPanel({ contentOrigins, onTopicSelect }: OriginsPanelProps) {
  const contentOrigin = useSelect();
  const multiContentOrigin = useMultiselect();
  const pathToContent = useInput();
  const originId = useInput();
  const [headers, setHeaders] = useState<Array<HeaderItem>>([{ name: '', value: '' }]);
  return (
    <Container header={<Header>Origin settings</Header>}>
      <SpaceBetween size="l">
        <FormField
          label="Content origin"
          info={
            <Link variant="info" onFollow={() => onTopicSelect('contentOrigin')}>
              Info
            </Link>
          }
          description="The Amazon S3 bucket or web server from which you want CloudFront to get your web content."
        >
          <Select
            {...contentOrigin}
            options={contentOrigins}
            placeholder="Select an S3 bucket or web server from which you want CloudFront to get your web content."
            filteringType="auto"
          />
        </FormField>
        <FormField
          label="Content origin (multiselect version)"
          description="The Amazon S3 bucket or web server from which you want CloudFront to get your web content."
        >
          <Multiselect
            {...multiContentOrigin}
            options={contentOrigins}
            placeholder="Select an S3 bucket or web server from which you want CloudFront to get your web content."
            filteringType="auto"
          />
        </FormField>
        <FormField label="Path to content" description="The directory in your Amazon S3 bucket or your custom origin.">
          <Input {...pathToContent} placeholder="/images" />
        </FormField>
        <FormField
          label="Origin ID"
          description="This value lets you distinguish multiple origins in the same distribution from one another"
        >
          <Input {...originId} />
        </FormField>
        <AttributeEditor<HeaderItem>
          addButtonText="Add header"
          removeButtonText="Remove header"
          items={headers}
          definition={[
            {
              label: <span>Custom header name</span>,
              info: (
                <Link href="#/create" onFollow={() => onTopicSelect('customHeaders')}>
                  Info
                </Link>
              ),
              control: (item, index) => (
                <Input
                  value={item.name}
                  placeholder="Location"
                  onChange={event =>
                    setHeaders(headers =>
                      updateHeader(headers, index, header => ({ ...header, name: event.detail.value }))
                    )
                  }
                />
              )
            },
            {
              label: (
                <span>
                  Custom header value
                  <i> - optional</i>
                </span>
              ),
              control: (item, index) => (
                <Input
                  value={item.value}
                  placeholder="Germany"
                  onChange={event =>
                    setHeaders(headers =>
                      updateHeader(headers, index, header => ({ ...header, value: event.detail.value }))
                    )
                  }
                />
              )
            }
          ]}
          onAddButtonClick={() => setHeaders(headers => headers.concat({ name: '', value: '' }))}
          onRemoveButtonClick={event =>
            setHeaders(headers => headers.filter((item, index) => index !== event.detail.itemIndex))
          }
        />
      </SpaceBetween>
    </Container>
  );
}
