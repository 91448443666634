import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import { Breadcrumbs, ServiceNavigation } from '../navigation';
import { appLayoutLabels } from '../../common/labels';
import {useEffect, useState} from "react";
import Flashbar, {FlashbarProps} from "@amzn/awsui-components-react/polaris/flashbar";
import ToolsPanel from "../reports/ToolsPanel";
import ReportsFormPage from "./FormPage"
import * as constants from '../../common/constants';
import { useDocumentTitle } from '../hooks';
import { ContentLayout, Header } from '@amzn/awsui-components-react';


export default function ReportsFormView() {
    useDocumentTitle(constants.CREATE_REPORT_TAB_TITLE)
    const [flashbar, setFlashbar] = useState<FlashbarProps.MessageDefinition[]>([])

    return (
        <AppLayout
            headerSelector='#topNavigation'
            navigation={<ServiceNavigation />}
            breadcrumbs={<Breadcrumbs items={[
                { text: constants.FONDUE_LABEL, href:'' },
                { text: constants.REPORTS_LABEL, href: '#/reports' },
                { text: constants.CREATE_REPORT_FORM, href: '' }]} />}
            content={ <ContentLayout header={
                        <Header variant="h1">
                            Create Report Form
                        </Header>
                    }>
                    <ReportsFormPage setFlashbar={setFlashbar}/>
                </ContentLayout>
            }
            contentType="form"
            notifications={<Flashbar items={flashbar} />}
            toolsHide={true}
            ariaLabels={appLayoutLabels}
        />
    );
}
