import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import { FlashbarProps } from "@amzn/awsui-components-react/polaris/flashbar";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Header from "@amzn/awsui-components-react/polaris/header";
import Input from "@amzn/awsui-components-react/polaris/input";
import Link from "@amzn/awsui-components-react/polaris/link";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import Select from "@amzn/awsui-components-react/polaris/select";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import { derReportType, wbrReportType } from "../../common/ReportTypes";
import * as constants from "../../common/constants";
import FondueApiFactory from "../../fondue-api/FondueApiFactory";
import { FondueApi, Report } from "../../fondue-api/generated-src";
import { DateUtil } from "../../util/DateUtil";
import { ExpandableSection } from "@amzn/awsui-components-react";

const flashbarInvalidInput: FlashbarProps.MessageDefinition[] = [
    { type: 'error', content: 'Invalid Input: Name, Owner and Bindle are required fields', dismissible: true }
];

const flashbarSubmittingInput: FlashbarProps.MessageDefinition[] = [
    { type: 'info', content: 'Creating Report', dismissible: true }
];

const KnownCreateReportErrors = [400, 403, 404, 409] // List of known/custom report creation errors
const genericErrorMessage = "Error creating Report";

export default function ReportsFormPage({setFlashbar}) {
    Object.freeze(Object.prototype);
    async function createReport() {
        await getMidwayJwtToken();
        // If name or owner field not present, set InvalidInput Flashbar
        if(!reportName || !reportOwner 
            || (!useDefaultBindle && !reportBindle)) {
            setFlashbar(flashbarInvalidInput)
            return;
        }

        const FondueApi = FondueApiFactory();

        const report: Report = {
            id: '',
            name: reportName,
            owner: reportOwner,
            state: 'DISABLED',
            category: reportCategory,
            project: reportProject,
            report_item_order: [],
            bindle: reportBindle,
            report_type: reportType
        };
        setFlashbar(flashbarSubmittingInput)
        setCreateButtonDisabled(true);
        // TODO: should add check in the backend with Brass if user has sufficient permissions
        // Insufficient permissions flashbar if returns 403
        // https://sim.amazon.com/issues/P85651306
        await FondueApi.createReport(report).then((_) => {
            // Redirect to Reports Page after submitting
            history.push('/reports')
        }).catch((error) => {
            
            if (KnownCreateReportErrors.indexOf(error.response.status) > -1) {
                // Only show known errors messages
                setFlashbar([{ type: "error", content: error.response.data.message, dismissible: true }]);
            } else {
                // Generic Error
                setFlashbar([{ type: "error", content: genericErrorMessage, dismissible: true }]);
            }
            setCreateButtonDisabled(false);
        });
    }


    const [reportName, setReportName] = useState("")
    const [reportOwner, setReportOwner] = useState("")
    const [reportCategory, setReportCategory] = useState("")
    const [reportProject, setReportProject] = useState("")
    const [reportBindle, setReportBindle] = useState("")
    const [reportType, setReportType] = useState(wbrReportType.value)
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

    const [useDefaultBindle, setUseDefaultBindle] = useState(true);
    const [bindleOption, setBindleOption] = useState("default")
    const [isUserDerAuthorized, setisUserDerAuthorized] = useState(false);
    const [isUserWbrAuthorized, setisUserWbrAuthorized] = useState(false);
    const [selectedReportOption, setSelectedReportOption] = useState<OptionDefinition | null>(null);
    const history = useHistory();
    const [userAlias, setUserAlias] = useState<string>();

    // Auto fill current user's alias into "Report Owner" field for DERs.
    useEffect(() => {
        if (userAlias && reportType === derReportType.value && !reportOwner) {
            setReportOwner(userAlias);
        }
    }, [userAlias, reportType, reportOwner, setReportOwner]);

    async function validateUserDerAuthorization(FondueApi: FondueApi) {
        await getMidwayJwtToken();
        await FondueApi.validateReportTypeAuthorization()
            .then((response) => {
                let defaultSelectedOptionSet = false;
                if (response.data.wbr_authorized) {
                    setisUserWbrAuthorized(true);
                    if (!defaultSelectedOptionSet){
                        setSelectedOptionFields({selectedOption: wbrReportType.selectOption});
                        defaultSelectedOptionSet = true;
                    }
                }

                if (response.data.der_authorized) {
                    setisUserDerAuthorized(true);
                    if (!defaultSelectedOptionSet) {
                        setSelectedOptionFields({selectedOption: derReportType.selectOption});
                    }
                }

                setUserAlias(response.data.user);
            });
    }

    function setSelectedOptionFields(detail){
        setSelectedReportOption(detail.selectedOption)
        switch (detail.selectedOption.value) {
            case "wbr":
                setReportType(wbrReportType.value);
                break;
            case "der":
                setReportType(derReportType.value);
                setReportBindle("");
                break;
        }
    }

    function setBindleOptionFields(detail){
        setBindleOption(detail.value)
        switch (detail.value) {
            case "default":
                setUseDefaultBindle(true);
                setReportBindle("");
                break;
            case "custom":
                setUseDefaultBindle(false);
                setReportBindle("");
                break;
        }
    }

    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();
            await validateUserDerAuthorization(FondueApi)
        })();
    }, [FondueApiFactory]);

    // Auto-generate report name for DER reports
    useEffect(() => {
        if (reportType === derReportType.value && reportName === "") {
            setReportName("Report " + DateUtil.getTimestamp());
        }
    }, [reportType]);

    const optionalFields = (
        <>
            <FormField
                id="category"
                description={constants.CREATE_REPORT_CATEGORY_DESCRIPTION}
                label={
                    <span>
                        {constants.REPORT_CATEGORY_LABEL} <i> - optional</i>
                    </span>
                }
            >
                <Input value={reportCategory} onChange={(event) => setReportCategory(event.detail.value)} />
            </FormField>
            <FormField
                id="project"
                description={constants.CREATE_REPORT_PROJECT_DESCRIPTION}
                label={
                    <span>
                        {constants.REPORT_PROJECT_LABEL} <i> - optional</i>
                    </span>
                }
            >
                <Input value={reportProject} onChange={(event) => setReportProject(event.detail.value)} />
            </FormField>
        </>
    );
    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="l">
                    <Button id="cancel" variant="link" href={`#/reports`}>
                        {constants.CANCEL}
                    </Button>
                    <Button id="submit" variant="primary" onClick={createReport} disabled={createButtonDisabled || selectedReportOption == null}>
                        {constants.SUBMIT}
                    </Button>
                </SpaceBetween>
            }
        >
            <Container
                header={
                    <Header variant="h2" description='Create Fondue Report'>
                        {constants.CREATE_REPORT}
                    </Header>
                }
            >
            <SpaceBetween direction="vertical" size="l">
            <FormField id="type" label={constants.REPORT_TYPE_LABEL}>
                <Select
                    selectedOption={selectedReportOption}
                    onChange={ ({ detail }) => setSelectedOptionFields(detail) }
                    options={[{...wbrReportType.selectOption, disabled: !isUserWbrAuthorized}, {...derReportType.selectOption, disabled: !isUserDerAuthorized}]}
                />
            </FormField>
            <FormField id="name" description={constants.CREATE_REPORT_NAME_DESCRIPTION} label={constants.REPORT_NAME_LABEL}>
                <Input
                    value = {reportName}
                    onChange={event =>
                        setReportName(event.detail.value)}
                />
            </FormField>
            <FormField id="owner" description={constants.CREATE_REPORT_OWNER_DESCRIPTION} label={constants.REPORT_OWNER_LABEL}>
                <Input
                    value={reportOwner}
                    onChange={event =>
                        setReportOwner(event.detail.value)
                    }
                />
            </FormField>

            {/* For WBR reports, the optional fields go here */}
            {reportType === wbrReportType.value ? optionalFields : null}

            <FormField 
                id="bindle" 
                description={
                    <div>
                        {reportType === wbrReportType.value ? 
                        constants.CREATE_REPORT_PERMISSION_BINDLE_DESCRIPTION : 
                        constants.CREATE_REPORT_PERMISSION_BINDLE_DESCRIPTION_DETAILED}
                        {' '}                         
                        <Link
                            href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/Bindles"
                            target="_blank"
                        >
                            Setup bindle permission
                        </Link>
                    </div>
                }
                label={constants.CREATE_REPORT_PERMISSION_BINDLE_LABEL}
            >
                {reportType === wbrReportType.value ?
                <RadioGroup
                    id="bindle-option-radio"
                    onChange={({ detail }) => setBindleOptionFields(detail)}
                    value={bindleOption}
                    items={[
                        {
                        value: "default",
                        label:
                            <div>
                                {constants.CREATE_REPORT_DEFAULT_BINDLE_LABEL} - {' '}
                                <Link
                                    href="https://bindles.amazon.com/software_app/BizmetFondueReports"
                                    target="_blank"
                                >
                                    {constants.CREATE_REPORT_BUSINESS_METRICS_BINDLE_LABEL}
                                </Link>
                            </div>
                        ,
                        description: constants.CREATE_REPORT_DEFAULT_BINDLE_DESCRIPTION
                        },
                        {
                        value: "custom",
                        label: constants.CREATE_REPORT_CUSTOM_BINDLE_LABEL,
                        description: constants.CREATE_REPORT_CUSTOM_BINDLE_DESCRIPTION
                        }
                    ]}  
                /> : <></>
                }
                <Input
                    value = {reportBindle}
                    placeholder = 'amzn1.bindle.resource.abcdefgehtkjdalkjg'
                    onChange={event =>
                        setReportBindle(event.detail.value)
                    }
                    disabled={reportType === wbrReportType.value && useDefaultBindle}
                />
            </FormField>

            {/* For DER reports, the optional fields go here, under the collapsible section */}
            {reportType === derReportType.value ? (
                <ExpandableSection headerText="Advanced">
                    <SpaceBetween direction="vertical" size="xs">{optionalFields}</SpaceBetween>
                </ExpandableSection>
            ) : null}
            </SpaceBetween>
            </Container>
        </Form>
       
    );
}
