interface CustomStateParams {
    customSuccessMessage?: string
    customErrorMessage?: string
    customInfoMessage?: string
}

const setFlashbarCustomError: Function = (customMessage, setFlashbarFunction) => [
    { type: 'error', content: customMessage, dismissible: true, onDismiss: () => setFlashbarFunction([]) }
];
const setFlashbarCustomInfo: Function = (customMessage, setFlashbarFunction) => [
    { type: 'info', content: customMessage, dismissible: true, onDismiss: () => setFlashbarFunction([]) }
];
const setFlashbarCustomSuccess: Function = (customMessage, setFlashbarFunction) => [
    { type: 'success', content: customMessage, dismissible: true, onDismiss: () => setFlashbarFunction([]) }
];

export { CustomStateParams, setFlashbarCustomError, setFlashbarCustomInfo, setFlashbarCustomSuccess}