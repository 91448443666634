import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import FondueApiFactory from '../../fondue-api/FondueApiFactory';
import { Report, FondueApi } from '../../fondue-api/generated-src';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { getMidwayJwtToken } from "../../auth/MidwayJwtToken";
import axios from "axios";
import {getAppSetting, initializeAppSettings} from "../../config/AppSettings";
import {States} from "../../common/States";
import CollectionPreferences, {CollectionPreferencesProps} from "@amzn/awsui-components-react/polaris/collection-preferences";
import Link from '@amzn/awsui-components-react/polaris/link';
import Icon from "@amzn/awsui-components-react/polaris/icon";
import { ContentLayout } from '@amzn/awsui-components-react';
export interface ReportsTableProps {
    setState: (x: States) => void;
    state: States;
}

export default function ReportsTable({setState, state}: ReportsTableProps) {
    Object.freeze(Object.prototype);
    const [allItems, setAllItems] = useState<Report[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [maxId, setMaxId] = useState(0);
    const history = useHistory();

    const [pageSize, setPageSize] = useState(10);
    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: pageSize,
        wrapLines: true,
        visibleContent: ["name", "category", "project", "owner", "num_items", "schedule_state"]
    });

    const createButton = (
        <Button variant="primary" href={`#reports/create`}>
            Create
        </Button>
    );

    async function listReports(FondueApi: FondueApi, counter: number) {
        await getMidwayJwtToken();
        if(counter < 3){
            // Setting limit to 50 reports for now
            await FondueApi.listReports()
                .then((response) =>{
                    setAllItems(response.data['results'])
                    setState(States.success)
                })
                .catch((e) => {
                    if(e.response.status==403){
                        console.log('User is not authorized to use Fondue.')
                        setState(States.forbidden)
                    }
                    else{
                        setState(States.error)
                    }
                    listReports(FondueApi, counter + 1)
                })
        }
    }

    useEffect(() => {
        (async () => {
            const FondueApi = FondueApiFactory();

            // Call listReports which will retry up to 3 times
            await listReports(FondueApi, 0)
        })();


    }, [refreshTableFlag, setAllItems, FondueApiFactory]);


    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={createButton}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: pageSize
            },
            sorting: {},
            selection: {}
        }
    );

    function handlePreferences(detail){
        setPreferences(detail);
        setPageSize(detail.pageSize as number);
    }



    const { selectedItems } = collectionProps;

    return (
        <Table
            {...collectionProps}
            loading={!allItems && state === States.loading}
            loadingText="Loading reports"
            selectionType="single"
            header={
                    <Header
                    counter={
                        allItems &&
                        (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                            {createButton}
                        </SpaceBetween>
                    }
                    description={
                    <>
                        Don't see your report in the list? 
                        <Link fontSize='body-s' href="https://w.amazon.com/bin/view/BusinessMetrics/Fondue/Bindles" target="_blank">
                            <span> Learn more <Icon size='small' name="external" variant="link" /> </span>
                        </Link>
                    </>
                    }
                    >
                    Reports
                </Header>
            }
            variant= 'full-page'
            columnDefinitions={COLUMN_DEFINITIONS}
            visibleColumns={preferences.visibleContent}
            resizableColumns
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                    filteringPlaceholder='Find reports'
                />
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={preferences}
                    pageSizePreference={{
                        title: "Select page size",
                        options: [
                            { value: 10, label: "10 reports" },
                            { value: 20, label: "20 reports" },
                            { value: 50, label: "50 reports" }
                        ]
                    }}
                    onConfirm={({ detail }) => handlePreferences(detail)}
                    visibleContentPreference={{
                        title: "Preferences",
                        options: [
                            {
                                label: "Available columns",
                                options: [
                                    { id: "name", label: "name", editable: false },
                                    { id: "result", label: "result" },
                                    { id: "category", label: "category" },
                                    { id: "project", label: "project" },
                                    { id: "id", label: "id" },
                                    { id: "owner", label: "owner"},
                                    { id: "num_items", label: "# of items" },
                                    { id: "schedule_state", label: "schedule"}
                                ]
                            }
                        ]
                    }}
                />
            }
        />
    );
}
